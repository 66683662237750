import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { withRouter, Link } from "react-router-dom";

//i18n
import { withTranslation } from "react-i18next";

// users
import { logoutUser, getUserData } from "store/actions";

import { connect } from "react-redux";
import moment from "moment";
import SweetAlert from "react-bootstrap-sweetalert";

class ProfileMenu extends Component {
  state = {
    userData: {},
    menu: false,
    sessionExpiredModal: false,
  };

  componentDidMount() {
    this.props.onGetUserData();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props !== prevProps) {
      this.setState({
        userData: this.props.userData,
      });

      this.sessionExpired(this.props.userData.x_login_expiry);
    }

    // console.log("User data:", this.props.userData);
  }

  sessionExpired(expiry_time) {
    let login_expiry = moment(expiry_time);
    let currentDate = moment(new Date());

    let hourDelta = currentDate.format("h");
    let minuteDelta = currentDate.format("m");
    let secondDelta = currentDate.format("s");

    let timeToExpiry = login_expiry.subtract({
      hours: hourDelta,
      minutes: minuteDelta,
      seconds: secondDelta,
    });

    let secondsToExpiry = parseInt(timeToExpiry.format("m")) * 60;
    // + parseInt(timeToExpiry.format("s"));

    setTimeout(() => {
      console.log("Login token expired");
      this.setState({ sessionExpiredModal: true });
      // this.logOut();
    }, secondsToExpiry * 1000);

    console.log("Minutes to expiry", timeToExpiry.format("m"));
  }

  logOut() {
    this.props.onLogoutUser(this.props.history);
  }

  render() {
    return (
      <div ref={this.props.reference}>
        <React.Fragment>
          {this.state.sessionExpiredModal ? (
            <SweetAlert
              title="Session expired"
              warning
              onConfirm={this.logOut.bind(this)}
            >
              <p>
                Your session has expired because of security reasons, please log
                in again.
              </p>
            </SweetAlert>
          ) : null}
          <Dropdown
            isOpen={this.state.menu}
            toggle={() => {
              this.setState(prevState => ({ menu: !prevState.menu }));
            }}
            className="d-inline-block"
          >
            <DropdownToggle
              className="btn header-item"
              id="page-header-user-dropdown"
              tag="button"
            >
              <img
                className="rounded-circle header-profile-user"
                src={`https://ui-avatars.com/api/?name=${this.props.userData.first_name}+${this.props.userData.last_name}`}
                alt="Header Avatar"
              />{" "}
              <span
                className="d-none d-xl-inline-block ms-1"
                style={{
                  textTransform: "capitalize",
                  marginRight: "4px",
                }}
              >
                {`${this.props.userData.first_name} ${this.props.userData.last_name}`}
              </span>
              <i className="mdi mdi-chevron-down d-none d-xl-inline-block" />
            </DropdownToggle>
            <DropdownMenu className="dropdown-menu-end">
              {/* <DropdownItem tag="a" href="/profile">
              <span className="badge bg-success float-end">11</span>
              <i className="bx bx-user font-size-16 align-middle ms-1" />
              <span>Profile</span>
            </DropdownItem> */}
              <DropdownItem tag="a" href="#"></DropdownItem>
              <Link to="/account-settings" className="dropdown-item">
                <i className="bx bx-wrench font-size-17 align-middle me-1" />
                <span>Settings</span>
              </Link>
              <div className="dropdown-divider" />
              <Link
                to="/logout"
                className="dropdown-item"
                onClick={e => {
                  e.preventDefault();
                  this.logOut();
                }}
              >
                <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger" />
                <span>Logout</span>
              </Link>
            </DropdownMenu>
          </Dropdown>
        </React.Fragment>
      </div>
    );
  }
}

ProfileMenu.propTypes = {
  history: PropTypes.any,
  onLogoutUser: PropTypes.func,
  userData: PropTypes.any,
  onGetUserData: PropTypes.any,
  reference: PropTypes.object,
};

const mapStateToProps = state => ({
  userData: state.Login.userData,
});

const mapDispatchToProps = dispatch => ({
  onLogoutUser: history => dispatch(logoutUser(history)),
  onGetUserData: () => dispatch(getUserData()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ProfileMenu));
