import React, { useState, useEffect } from "react";
import "index.css";

import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import { cities } from "constants/cities";
import { states } from "constants/states";

function AccountSettings() {
  const userData = JSON.parse(localStorage.getItem("userData"));

  const [validated, setValidated] = useState(false);
  const [firstName, setFirstName] = useState(userData.first_name);
  const [lastName, setLastName] = useState(userData.last_name);
  const [selectedCity, setSelectedCity] = useState("");
  const [selectedState, setSelectedState] = useState("California");
  const [zip, setZip] = useState("");
  const [married, setMarried] = useState("No");
  const [dependents, setDependents] = useState(0);

  const handleSubmit = event => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }

    setValidated(true);
  };

  return (
    <div
      style={{
        marginTop: "150px",
        display: "flex",
        flexDirection: "column",
        padding: "10px",
      }}
    >
      <div className="update-user-heading">Update Account Settings</div>
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <Row className="mb-3">
          <Form.Group as={Col} controlId="validationCustom01">
            <Form.Label>First name</Form.Label>
            <Form.Control
              required
              type="text"
              placeholder="First name"
              defaultValue={firstName}
              onChange={e => {
                setFirstName(e.target.value);
              }}
            />
            <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
          </Form.Group>
        </Row>
        <Row className="mb-3">
          <Form.Group as={Col} controlId="validationCustom02">
            <Form.Label>Last name</Form.Label>
            <Form.Control
              required
              type="text"
              placeholder="Last name"
              defaultValue={lastName}
              onChange={e => {
                setLastName(e.target.value);
              }}
            />
            <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
          </Form.Group>
        </Row>
        <Row className="mb-3">
          <Form.Group as={Col} md="4" controlId="validationCustom04">
            <Form.Label>State</Form.Label>
            <Form.Select
              defaultValue={selectedState}
              value={selectedState}
              onChange={e => setSelectedState(e.target.value)}
            >
              {states.map((state, index) => {
                return <option key={index}>{state.name}</option>;
              })}
            </Form.Select>
            <Form.Control.Feedback type="invalid">
              Please provide a valid state.
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group as={Col} md="4" controlId="validationCustom03">
            <Form.Label>City</Form.Label>
            <Form.Select
              defaultValue={selectedCity}
              value={selectedCity}
              onChange={e => setSelectedCity(e.target.value)}
            >
              {cities[selectedState].map((city, index) => {
                return <option key={index}>{city}</option>;
              })}
            </Form.Select>
            <Form.Control.Feedback type="invalid">
              Please provide a valid city.
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group as={Col} md="4" controlId="validationCustom05">
            <Form.Label>Zip</Form.Label>
            <Form.Control
              type="text"
              placeholder="Zip"
              defaultValue={zip}
              required
              onChange={e => {
                setZip(e.target.value);
              }}
            />
            <Form.Control.Feedback type="invalid">
              Please provide a valid zip.
            </Form.Control.Feedback>
          </Form.Group>
        </Row>
        <Row className="mb-4">
          <Form.Group as={Col} md="6" controlId="validationCustom06">
            <Form.Label>Are you married ?</Form.Label>
            <Form.Select
              defaultValue={married}
              onChange={e => {
                setMarried(e.target.value);
                setDependents(0);
              }}
            >
              <option>Yes</option>
              <option>No</option>
            </Form.Select>
          </Form.Group>
          <Form.Group as={Col} controlId="validationCustom07">
            <Form.Label>Number of dependents</Form.Label>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <Button
                style={{ borderRadius: "50%" }}
                size="sm"
                onClick={() => {
                  setDependents(dependents + 1);
                }}
                disabled={married === "No"}
              >
                +
              </Button>
              <div style={{ margin: "0px 10px" }}>{dependents}</div>
              <Button
                disabled={dependents <= 0}
                style={{ borderRadius: "50%" }}
                size="sm"
                onClick={() => {
                  setDependents(dependents - 1);
                }}
              >
                -
              </Button>
            </div>
            <Form.Control.Feedback type="invalid">
              Please provide a number.
            </Form.Control.Feedback>
          </Form.Group>
        </Row>
        <Button type="submit">Update</Button>
        <Row className="mb-3"></Row>
      </Form>
    </div>
  );
}

export default AccountSettings;
