import {
  LOGIN_USER,
  LOGIN_SUCCESS,
  LOGOUT_USER,
  LOGOUT_USER_SUCCESS,
  LOGIN_FAIL,
  UPDATE_USER_DATA,
  GET_USER_DATA,
} from "./actionTypes";

export const loginUser = (user, history) => {
  return {
    type: LOGIN_USER,
    payload: { user, history },
  };
};

// export const loginSuccess = user => {
//   return {
//     type: LOGIN_SUCCESS,
//     payload: user,
//   };
// };

export const getUserData = () => {
  return {
    type: GET_USER_DATA,
  };
};

export const updateUserData = data => {
  return {
    type: UPDATE_USER_DATA,
    payload: data,
  };
};

export const logoutUser = history => {
  return {
    type: LOGOUT_USER,
    payload: { history },
  };
};

export const logoutUserSuccess = () => {
  return {
    type: LOGOUT_USER_SUCCESS,
    payload: {},
  };
};

export const loginUserFailed = error => {
  return {
    type: LOGIN_FAIL,
    payload: error,
  };
};
