import { SET_DATE_RANGE, RESET_DATE_RANGE } from "./actionTypes";

export const setDateRange = data => ({
  type: SET_DATE_RANGE,
  payload: data,
});

export const resetDateRange = data => ({
  type: RESET_DATE_RANGE,
  payload: data,
});
