import React, { Component } from "react";
import PropTypes from "prop-types";
import { isListUpdated } from "services/helpers/funcions";

import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";

class Graph extends Component {
  componentDidMount() {
    let root = am5.Root.new("chartdiv");

    let data = [
      {
        date: "2012-07-27",
        value: 13,
      },
      {
        date: "2012-07-28",
        value: 11,
      },
      {
        date: "2012-07-29",
        value: 15,
      },
      {
        date: "2012-07-30",
        value: 16,
      },
      {
        date: "2012-07-31",
        value: 18,
      },
      {
        date: "2012-08-01",
        value: 13,
      },
      {
        date: "2012-08-02",
        value: 22,
      },
      {
        date: "2012-08-03",
        value: 23,
      },
      {
        date: "2012-08-04",
        value: 20,
      },
      {
        date: "2012-08-05",
        value: 17,
      },
      {
        date: "2012-08-06",
        value: 16,
      },
      {
        date: "2012-08-07",
        value: 18,
      },
      {
        date: "2012-08-08",
        value: 21,
      },
      {
        date: "2012-08-09",
        value: 26,
      },
      {
        date: "2012-08-10",
        value: 24,
      },
      {
        date: "2012-08-11",
        value: 29,
      },
      {
        date: "2012-08-12",
        value: 32,
      },
      {
        date: "2012-08-13",
        value: 18,
      },
      {
        date: "2012-08-14",
        value: 24,
      },
      {
        date: "2012-08-15",
        value: 22,
      },
      {
        date: "2012-08-16",
        value: 18,
      },
      {
        date: "2012-08-17",
        value: 19,
      },
      {
        date: "2012-08-18",
        value: 14,
      },
      {
        date: "2012-08-19",
        value: 15,
      },
      {
        date: "2012-08-20",
        value: 12,
      },
      {
        date: "2012-08-21",
        value: 8,
      },
      {
        date: "2012-08-22",
        value: 9,
      },
      {
        date: "2012-08-23",
        value: 8,
      },
      {
        date: "2012-08-24",
        value: 7,
      },
      {
        date: "2012-08-25",
        value: 5,
      },
      {
        date: "2012-08-26",
        value: 11,
      },
      {
        date: "2012-08-27",
        value: 13,
      },
      {
        date: "2012-08-28",
        value: 18,
      },
      {
        date: "2012-08-29",
        value: 20,
      },
      {
        date: "2012-08-30",
        value: 29,
      },
      {
        date: "2012-08-31",
        value: 33,
      },
      {
        date: "2012-09-01",
        value: 42,
      },
      {
        date: "2012-09-02",
        value: 35,
      },
      {
        date: "2012-09-03",
        value: 31,
      },
      {
        date: "2012-09-04",
        value: 47,
      },
      {
        date: "2012-09-05",
        value: 52,
      },
      {
        date: "2012-09-06",
        value: 46,
      },
      {
        date: "2012-09-07",
        value: 41,
      },
      {
        date: "2012-09-08",
        value: 43,
      },
      {
        date: "2012-09-09",
        value: 40,
      },
      {
        date: "2012-09-10",
        value: 39,
      },
      {
        date: "2012-09-11",
        value: 34,
      },
      {
        date: "2012-09-12",
        value: 29,
      },
      {
        date: "2012-09-13",
        value: 34,
      },
      {
        date: "2012-09-14",
        value: 37,
      },
      {
        date: "2012-09-15",
        value: 42,
      },
      {
        date: "2012-09-16",
        value: 49,
      },
      {
        date: "2012-09-17",
        value: 46,
      },
      {
        date: "2012-09-18",
        value: 47,
      },
      {
        date: "2012-09-19",
        value: 55,
      },
      {
        date: "2012-09-20",
        value: 59,
      },
      {
        date: "2012-09-21",
        value: 58,
      },
      {
        date: "2012-09-22",
        value: 57,
      },
      {
        date: "2012-09-23",
        value: 61,
      },
      {
        date: "2012-09-24",
        value: 59,
      },
      {
        date: "2012-09-25",
        value: 67,
      },
      {
        date: "2012-09-26",
        value: 65,
      },
      {
        date: "2012-09-27",
        value: 61,
      },
      {
        date: "2012-09-28",
        value: 66,
      },
      {
        date: "2012-09-29",
        value: 69,
      },
      {
        date: "2012-09-30",
        value: 71,
      },
      {
        date: "2012-10-01",
        value: 67,
      },
      {
        date: "2012-10-02",
        value: 63,
      },
      {
        date: "2012-10-03",
        value: 46,
      },
      {
        date: "2012-10-04",
        value: 32,
      },
      {
        date: "2012-10-05",
        value: 21,
      },
      {
        date: "2012-10-06",
        value: 18,
      },
      {
        date: "2012-10-07",
        value: 21,
      },
      {
        date: "2012-10-08",
        value: 28,
      },
      {
        date: "2012-10-09",
        value: 27,
      },
      {
        date: "2012-10-10",
        value: 36,
      },
      {
        date: "2012-10-11",
        value: 33,
      },
      {
        date: "2012-10-12",
        value: 31,
      },
      {
        date: "2012-10-13",
        value: 30,
      },
      {
        date: "2012-10-14",
        value: 34,
      },
      {
        date: "2012-10-15",
        value: 38,
      },
      {
        date: "2012-10-16",
        value: 37,
      },
      {
        date: "2012-10-17",
        value: 44,
      },
      {
        date: "2012-10-18",
        value: 49,
      },
      {
        date: "2012-10-19",
        value: 53,
      },
      {
        date: "2012-10-20",
        value: 57,
      },
      {
        date: "2012-10-21",
        value: 60,
      },
      {
        date: "2012-10-22",
        value: 61,
      },
      {
        date: "2012-10-23",
        value: 69,
      },
      {
        date: "2012-10-24",
        value: 67,
      },
      {
        date: "2012-10-25",
        value: 72,
      },
      {
        date: "2012-10-26",
        value: 77,
      },
      {
        date: "2012-10-27",
        value: 75,
      },
      {
        date: "2012-10-28",
        value: 70,
      },
      {
        date: "2012-10-29",
        value: 72,
      },
      {
        date: "2012-10-30",
        value: 70,
      },
      {
        date: "2012-10-31",
        value: 72,
      },
      {
        date: "2012-11-01",
        value: 73,
      },
      {
        date: "2012-11-02",
        value: 67,
      },
      {
        date: "2012-11-03",
        value: 68,
      },
      {
        date: "2012-11-04",
        value: 65,
      },
      {
        date: "2012-11-05",
        value: 71,
      },
      {
        date: "2012-11-06",
        value: 75,
      },
      {
        date: "2012-11-07",
        value: 74,
      },
      {
        date: "2012-11-08",
        value: 71,
      },
      {
        date: "2012-11-09",
        value: 76,
      },
      {
        date: "2012-11-10",
        value: 77,
      },
      {
        date: "2012-11-11",
        value: 81,
      },
      {
        date: "2012-11-12",
        value: 83,
      },
      {
        date: "2012-11-13",
        value: 80,
      },
      {
        date: "2012-11-14",
        value: 81,
      },
    ];

    root.setThemes([am5themes_Animated.new(root)]);

    root.dateFormatter.setAll({
      dateFormat: "yyyy",
      dateFields: ["valueX"],
    });

    let chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        focusable: true,
        panX: true,
        panY: false,
        wheelX: "panX",
        // wheelY: "zoomX",
        wheelY: "panX",
      })
    );

    let easing = am5.ease.linear;

    // Create Y-axis
    let yAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        maxDeviation: 0.2,
        renderer: am5xy.AxisRendererY.new(root, {}),
      })
    );

    // Create X-Axis
    let xAxis = chart.xAxes.push(
      am5xy.DateAxis.new(root, {
        maxDeviation: 0.1,
        groupData: false,
        baseInterval: {
          timeUnit: "day",
          count: 1,
        },
        renderer: am5xy.AxisRendererX.new(root, {}),
        tooltip: am5.Tooltip.new(root, {}),
      })
    );

    // Create series
    let expenseSeries = this.createSeries(
      chart,
      xAxis,
      yAxis,
      root,
      // 0x706afb,
      // 0xcea01d,
      0xd0ab3f,
      // 0xc2a44c,
      "expense"
    );

    let revenueSeries = this.createSeries(
      chart,
      xAxis,
      yAxis,
      root,
      0x5582e6,
      "revenue"
    );

    // Legend
    // var legend = chart.children.push(
    //   am5.Legend.new(root, {
    //     nameField: "name",
    //     fillField: "color",
    //     strokeField: "color",
    //     centerX: am5.percent(50),
    //     x: am5.percent(50),
    //     y: am5.percent(103),
    //     centerY: am5.percent(50),
    //   })
    // );

    // legend.data.setAll([
    //   {
    //     name: "Under budget",
    //     color: am5.color(0x297373),
    //   },
    //   {
    //     name: "Over budget",
    //     color: am5.color(0xff621f),
    //   },
    // ]);
    // var legend = chart.children.push(
    //   am5.Legend.new(root, {
    //     nameField: "Expenses",
    //     centerX: am5.percent(50),
    //     x: am5.percent(50),
    //   })
    // );

    // legend.data.setAll(expenseSeries.dataItems);

    // var legend = chart.children.push(
    //   am5.Legend.new(root, {
    //     nameField: "Revenue",
    //     centerX: am5.percent(50),
    //     x: am5.percent(50),
    //   })
    // );

    // legend.data.setAll(revenueSeries.dataItems);

    let cursor = chart.set(
      "cursor",
      am5xy.XYCursor.new(root, {
        xAxis: xAxis,
        behavior: "none",
      })
    );
    cursor.lineY.set("visible", false);

    // add scrollbar
    chart.set(
      "scrollbarX",
      am5.Scrollbar.new(root, {
        orientation: "horizontal",
      })
    );

    chart.appear(1000, 100);

    this.root = root;
    this.expenseSeries = expenseSeries;
    this.revenueSeries = revenueSeries;
  }

  createSeries(chart, xAxis, yAxis, root, color, yAxisKey) {
    let series = chart.series.push(
      am5xy.SmoothedXLineSeries.new(root, {
        minBulletDistance: 10,
        connect: false,
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: yAxisKey,
        valueXField: "date",
        tooltip: am5.Tooltip.new(root, {
          pointerOrientation: "horizontal",
          labelText: "{valueY}",
        }),
        fill: am5.color(color),
        stroke: am5.color(color),
      })
    );

    series.fills.template.setAll({
      fillOpacity: 0.1,
      visible: true,
    });

    series.fills.template.set(
      "fillGradient",
      am5.LinearGradient.new(root, {
        stops: [
          {
            opacity: 0.8,
          },
          {
            opacity: 0.3,
          },
        ],
        rotation: 90,
      })
    );

    series.strokes.template.setAll({
      strokeWidth: 2,
    });

    series.data.processor = am5.DataProcessor.new(root, {
      dateFormat: "yyyy-MM-dd",
      dateFields: ["date"],
    });

    series.data.setAll(this.props.chartData);

    // series.bullets.push(function () {
    //   let circle = am5.Circle.new(root, {
    //     radius: 4,
    //     fill: root.interfaceColors.get("background"),
    //     stroke: series.get("fill"),
    //     strokeWidth: 2,
    //   });

    //   return am5.Bullet.new(root, {
    //     sprite: circle,
    //   });
    // });

    return series;
  }

  componentDidUpdate(prevProps) {
    if (isListUpdated(prevProps.chartData, this.props.chartData)) {
      this.expenseSeries.data.setAll(this.props.chartData);
      this.revenueSeries.data.setAll(this.props.chartData);
    }
  }

  componentWillUnmount() {
    if (this.root) {
      this.root.dispose();
    }
  }

  render() {
    return (
      <div>
        <div id="chartdiv" style={{ width: "100%", height: "370px" }}></div>
      </div>
    );
  }
}

Graph.propTypes = {
  chartData: PropTypes.array,
};

export default Graph;
