import {
  SUCCESS_MODAL,
  FAIL_MODAL,
  CLOSE_MODALS,
  API_ERROR,
  GET_REVENUE,
  GET_REVENUE_SUCCESS,
  ADD_NEW_REVENUE,
  UPDATE_REVENUE,
  DELETE_REVENUE,
  IMPORT_REVENUE,
} from "./actionTypes";

// MODAL METHODS
export const successModal = () => ({
  type: SUCCESS_MODAL,
});

export const failModal = () => ({
  type: FAIL_MODAL,
});

export const closeModals = () => ({
  type: CLOSE_MODALS,
});

export const apiStatus = message => ({
  type: API_ERROR,
  payload: message,
});

// API METHODS
export const getRevenue = () => ({
  type: GET_REVENUE,
});

export const getRevenueSuccess = revenue => ({
  type: GET_REVENUE_SUCCESS,
  payload: revenue,
});

export const addNewRevenue = revenue => ({
  type: ADD_NEW_REVENUE,
  payload: revenue,
});

export const updateRevenue = revenue => ({
  type: UPDATE_REVENUE,
  payload: revenue,
});

export const deleteRevenue = revenue => ({
  type: DELETE_REVENUE,
  payload: revenue,
});

export const importRevenue = data => ({
  type: IMPORT_REVENUE,
  payload: data,
});
