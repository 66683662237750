import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { isEmpty, size } from "lodash";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
} from "react-bootstrap-table2-paginator";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import { Link } from "react-router-dom";
import SweetAlert from "react-bootstrap-sweetalert";
import Alert from "react-bootstrap/Alert";
import noData from "../../assets/images/custom/no-graph.svg";

import {
  Button,
  Card,
  CardBody,
  Badge,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Input,
  FormText,
  Row,
  Col,
  Dropdown,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";

import {
  categoryOptions,
  reactSelectOptions,
} from "../../constants/global_variables";
import NumberFormat from "react-number-format";
import Select from "react-select";
import { Calendar } from "react-date-range";
import moment from "moment";

import {
  isListUpdated,
  isDateRangeUpdated,
  filterDataFromDateRange,
} from "services/helpers/funcions";

import {
  closeModals,
  getExpenses,
  addNewExpense,
  updateExpense,
  deleteExpense,
  importExpenses,
  getBanks,
  resetDateRange,
} from "store/actions";

class ExpensesTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isComponentUpdated: false,
      expenses: props.expenses,
      formState: "Add",
      formInvalidModal: false,
      datePickerOpen: false,

      // search, sort and filter
      searchQuery: "",
      filterQuery: "",
      filteredExpensesList: props.expenses,

      // Add expenses
      expenseModal: false,
      expenseDesc: "",
      expenseType: {
        value: null,
        label: null,
      },
      expenseAmt: "",
      expenseID: "",
      expenseDate: new Date(),

      // Delete expenses
      selectedItems: [],
      selectItems: false,
      noItemsSelectedModal: false,
      confirmDeleteModal: false,
      deletedModal: false,

      // Sorting
      sortStatus: false,
      sortDataField: "",
      sortOrder: "asc",
    };
  }

  componentDidMount() {
    const { expenses, onGetExpenses, dateRange, onGetBanks } = this.props;
    var tempExpenses = expenses;

    if (expenses && !expenses.length) {
      onGetExpenses();
    }

    if (dateRange) {
      tempExpenses = filterDataFromDateRange(dateRange, expenses);
    }

    this.setState({
      expenses: tempExpenses,
      filteredExpensesList: tempExpenses,
    });

    onGetBanks();
  }

  componentDidUpdate(prevProps) {
    var { expenses, dateRange } = this.props;
    let tempExpenses = expenses;

    if (isListUpdated(expenses, prevProps.expenses)) {
      this.setState({
        expenses: expenses,
        filteredExpensesList: expenses,
      });
    }

    if (isDateRangeUpdated(dateRange, prevProps.dateRange)) {
      if (dateRange) {
        tempExpenses = filterDataFromDateRange(dateRange, expenses);
      }

      this.setState({
        expenses: tempExpenses,
        filteredExpensesList: tempExpenses,
      });
    }
  }

  clearForm() {
    this.setState({
      expenseDesc: "",
      expenseType: {
        value: null,
        label: null,
      },
      expenseAmt: "",
    });
  }

  updateExpenseList() {
    this.props.onGetExpenses();
    const { expenses, onGetExpenses } = this.props;
    this.setState({ expenses: expenses, filteredExpensesList: expenses });
  }

  handleFormSubmit(e) {
    e.preventDefault();

    if (
      this.state.expenseAmt !== "" &&
      this.state.expenseDesc !== "" &&
      this.state.expenseType.value !== null
    ) {
      if (this.state.formState === "Add") {
        this.addExpense();
        this.updateExpenseList();
      } else if (this.state.formState === "Update") {
        this.handleUpdateExpense();
        this.updateExpenseList();
      }
    } else {
      this.setState({ formInvalidModal: true });
    }
  }

  // UPDATE EXPENSE
  triggerUpdateExpense(ID, desc, type, amount, date) {
    this.setState({
      formState: "Update",
      expenseModal: true,
      expenseDesc: desc,
      expenseType: {
        value: type,
        label: type,
      },
      expenseAmt: amount,
      expenseID: ID,
      expenseDate: moment(date).toDate(),
    });
  }

  handleUpdateExpense() {
    this.setState({ expenseModal: false });

    const { onUpdateExpense } = this.props;
    let amountString = this.state.expenseAmt;

    if (typeof amountString === "string") {
      amountString = this.state.expenseAmt.match(/\d+/g).join("");
    }

    onUpdateExpense({
      id: this.state.expenseID,
      data: {
        description: this.state.expenseDesc,
        sub_category: this.state.expenseType.value,
        amount: amountString,
        txn_date: this.state.expenseDate.toISOString().slice(0, 10),
      },
    });

    this.updateExpenseList();
    this.clearForm();
  }

  // ADD EXPENSE
  async addExpense() {
    this.setState({ expenseModal: false });
    this.clearForm();

    const { onAddNewExpense } = this.props;

    await onAddNewExpense([
      {
        category: "Office",
        sub_category: this.state.expenseType.value,
        amount: this.state.expenseAmt.match(/\d+/g).join(""),
        description: this.state.expenseDesc,
        txn_date: this.state.expenseDate.toISOString().slice(0, 10),
      },
    ]);

    this.updateExpenseList();
    this.forceUpdate();
  }

  // IMPORT EXPENSES
  importExpenses() {
    const { onImportExpense, bankAccounts } = this.props;
    onImportExpense(bankAccounts);
  }

  // DELETE EXPENSE
  triggerSelectItems = () => {
    if (!this.state.selectItems) {
      this.setState({ selectItems: true });
    } else {
      if (this.state.selectedItems.length === 0) {
        this.setState({ noItemsSelectedModal: true });
      } else {
        this.setState({ confirmDeleteModal: true });
      }
    }
  };

  async deleteExpenses() {
    const { onDeleteExpense } = this.props;
    await onDeleteExpense({ expense_ids: this.state.selectedItems });

    this.updateExpenseList();
    this.setState({
      deletedModal: true,
      selectItems: false,
      confirmDeleteModal: false,
    });
    this.forceUpdate();
  }

  tableRowSelect(row, isSelect, rowIndex, e) {
    if (this.state.selectedItems.includes(row.id)) {
      let el = this.state.selectedItems.indexOf(row.id.toString());
      this.state.selectedItems.splice(el, 1);
    } else {
      this.state.selectedItems.push(row.id);
    }
    // console.log(this.state.selectedItems);
  }

  tableRowSelectAll(isSelect, rows, e) {
    if (isSelect) {
      this.setState({ selectItems: [] });
      rows.forEach(el => {
        this.state.selectedItems.push(el.id.toString());
      });
    } else {
      this.state.selectedItems.splice(0, this.state.selectedItems.length);
    }
    // console.log(isSelect, this.state.selectedItems);
  }

  // SEARCH
  handleExpenseSearch(e) {
    let searchValue = e.target.value;

    this.setState(prevState => {
      let filterList = prevState.expenses.filter(el => {
        return el.description.search(new RegExp(searchValue, "i")) !== -1;
      });
      return { filteredExpensesList: filterList };
    });
  }

  // FILTER
  handleExpenseFilter(e) {
    if (e.value === "No Filter") {
      this.setState(prevState => ({
        filteredExpensesList: prevState.expenses,
      }));
    } else {
      this.setState(prevState => {
        let filterList = prevState.expenses.filter(el => {
          return el.sub_category === e.value.toLowerCase();
        });
        return { filteredExpensesList: filterList };
      });
    }
  }

  // SORT
  handleExpenseSort(e) {
    switch (e.value) {
      case "A-HL":
        this.setState({
          sortDataField: "amount",
          sortOrder: "desc",
        });
        break;

      case "A-LH":
        this.setState({
          sortDataField: "amount",
          sortOrder: "asc",
        });
        break;

      default:
        this.setState({
          sortDataField: "id",
          sortOrder: "desc",
        });
        break;
    }
  }

  render() {
    const { onCloseModals, expenses } = this.props;

    const tableConfig = {
      columns: [
        {
          text: "id",
          dataField: "id",
          sort: true,
          hidden: true,
          formatter: (cellContent, row) => <>{row.id}</>,
        },
        {
          dataField: "description",
          text: "Description",
        },
        {
          dataField: "txn_date",
          text: "Date",
          formatter: (cellContent, row) =>
            moment(row.txn_date).format("DD / MM / YYYY"),
        },
        {
          dataField: "sub_category",
          text: "Type",
          formatter: (cellContent, row) =>
            row.sub_category.charAt(0).toUpperCase() +
            row.sub_category.slice(1),
        },
        {
          dataField: "amount",
          text: "Amount",
          formatter: (cellContent, row) => (
            <Link to="#" className="text-body fw-bold">
              <NumberFormat
                thousandsGroupStyle="thousand"
                prefix="$ "
                decimalSeparator="."
                displayType="text"
                value={row.amount}
                thousandSeparator={true}
              />
            </Link>
          ),
        },
        {
          text: "",
          dataField: "",
          classes: "edit-cell",
          formatter: (cellContent, row) => (
            <div className="cell-wrapper">
              <div
                className="trigger"
                onClick={() => {
                  this.triggerUpdateExpense(
                    row.id,
                    row.description,
                    row.sub_category.charAt(0).toUpperCase() +
                      row.sub_category.slice(1),
                    row.amount,
                    row.txn_date
                  );
                }}
              >
                <i className="bx bx-pencil h4 m-0" />
              </div>
            </div>
          ),
        },
      ],

      pageOptions: {
        sizePerPage: 10,
        totalSize: expenses.length,
        custom: true,
      },

      defaultSorted: [
        {
          dataField: "id",
          order: "desc",
        },
      ],

      sort: {
        dataField: this.state.sortDataField,
        order: this.state.sortOrder,
      },

      selectRow: {
        mode: "checkbox",
        selectColumnPosition: "right",
        hideSelectColumn: !this.state.selectItems,
        onSelect: (row, isSelect, rowIndex, e) => {
          this.tableRowSelect(row, isSelect, rowIndex, e);
        },
        onSelectAll: (isSelect, rows, e) => {
          this.tableRowSelectAll(isSelect, rows, e);
        },
      },
    };

    return (
      <React.Fragment>
        {this.state.noItemsSelectedModal ? (
          <SweetAlert
            title="Alert!"
            warning
            onConfirm={() => this.setState({ noItemsSelectedModal: false })}
          >
            <p>Please select at least 1 expense to delete</p>
          </SweetAlert>
        ) : null}

        {this.state.formInvalidModal ? (
          <SweetAlert
            title="Form Incomplete!"
            warning
            onConfirm={() => this.setState({ formInvalidModal: false })}
          >
            <p>Please complete the form before submitting</p>
          </SweetAlert>
        ) : null}

        {this.props.successModal && !this.state.deletedModal ? (
          <SweetAlert
            title={this.props.apiStatusMessage.heading}
            success
            onConfirm={() => {
              onCloseModals();
            }}
          >
            <p>{this.props.apiStatusMessage.message}</p>
          </SweetAlert>
        ) : null}

        {this.props.failModal ? (
          <SweetAlert
            title={this.props.apiStatusMessage.heading}
            warning
            onConfirm={() => {
              onCloseModals();
            }}
          >
            <p>{this.props.apiStatusMessage.message}</p>
          </SweetAlert>
        ) : null}

        {this.state.confirmDeleteModal ? (
          <SweetAlert
            title="Are you sure?"
            warning
            showCancel
            confirmButtonText="Yes, delete it!"
            confirmBtnBsStyle="success"
            cancelBtnBsStyle="danger"
            onConfirm={() =>
              this.setState({
                confirmDeleteModal: false,
              })
            }
            customButtons={
              <>
                <Button
                  onClick={() =>
                    this.setState({
                      confirmDeleteModal: false,
                    })
                  }
                  className="mx-2"
                >
                  Cancel
                </Button>
                <Button
                  onClick={this.deleteExpenses.bind(this)}
                  className="mx-2"
                  color="danger"
                >
                  Delete
                </Button>
              </>
            }
          >
            You won&apos;t be able to revert this!
          </SweetAlert>
        ) : null}

        {this.props.successModal && this.state.deletedModal ? (
          <SweetAlert
            success
            title="Deleted"
            onConfirm={() => {
              this.setState({ deletedModal: false, selectedItems: [] });
              onCloseModals();
            }}
          >
            {this.state.selectedItems.length > 1 ? (
              <p>
                {this.state.selectedItems.length} expenses have been deleted
              </p>
            ) : (
              <p>{this.state.selectedItems.length} expense has been deleted</p>
            )}
          </SweetAlert>
        ) : null}
        {this.props.dateRange && (
          <Alert variant="primary">
            Displaying data from{" "}
            {this.props.dateRange.startDate.toString().slice(4, 10)} through{" "}
            {this.props.dateRange.endDate.toString().slice(4, 10)}.{" "}
            <span
              style={{ textDecoration: "underline", cursor: "pointer" }}
              onClick={() => {
                this.props.resetDateRange();
              }}
            >
              Reset?
            </span>
          </Alert>
        )}
        <Modal
          isOpen={this.state.expenseModal}
          className="modal-dialog-centered"
        >
          <ModalHeader
            toggle={() => {
              this.setState({ expenseModal: false });
              this.clearForm();
            }}
          >
            {this.state.formState} Expenses
          </ModalHeader>
          <ModalBody>
            <Form
              onSubmit={e => {
                this.handleFormSubmit(e);
              }}
            >
              <FormGroup>
                <Label for="expense-description">Description</Label>
                <Input
                  type="text"
                  name="expense description"
                  id="expense-description"
                  autoComplete="off"
                  placeholder="Expense description"
                  value={this.state.expenseDesc}
                  onChange={e => {
                    this.setState({ expenseDesc: e.target.value });
                  }}
                />
              </FormGroup>
              <FormGroup className="mt-3">
                <Label for="expense-amount">Date</Label>
                <div className="form-control p-0">
                  <Dropdown
                    className="date-picker-toggle"
                    isOpen={this.state.datePickerOpen}
                    toggle={() => {
                      this.setState({
                        datePickerOpen: !this.state.datePickerOpen,
                      });
                    }}
                  >
                    <DropdownToggle
                      className="btn d-flex align-items-center justify-content-between w-100"
                      tag="button"
                      style={{ boxShadow: "none" }}
                      onClick={e => {
                        e.preventDefault();
                      }}
                    >
                      <p className="m-0" style={{ paddingTop: "2px" }}>
                        {moment(this.state.expenseDate).format(
                          "DD / MM / YYYY"
                        )}
                      </p>
                      <i
                        className="far fa-calendar-alt font-size-20"
                        style={{ marginLeft: "10px" }}
                      />
                    </DropdownToggle>
                    {this.state.datePickerOpen ? (
                      <DropdownMenu className="datepicker-dropdown-menu">
                        <Calendar
                          date={this.state.expenseDate}
                          onChange={date => {
                            this.setState({ expenseDate: date });
                          }}
                        />
                      </DropdownMenu>
                    ) : null}
                  </Dropdown>
                </div>
              </FormGroup>
              <FormGroup className="mt-3">
                <Label for="expense-type">Type</Label>
                <Select
                  id="expense-type"
                  styles={reactSelectOptions}
                  value={this.state.expenseType}
                  onChange={e => {
                    this.setState({ expenseType: e });
                  }}
                  options={categoryOptions.map(el => ({
                    value: el,
                    label: el,
                  }))}
                />
              </FormGroup>
              <FormGroup className="mt-3">
                <Label for="expense-amount">Amount</Label>
                <NumberFormat
                  thousandsGroupStyle="thousand"
                  className="form-control"
                  prefix="$ "
                  placeholder="Enter the amount in USD"
                  decimalSeparator="."
                  displayType="input"
                  type="text"
                  thousandSeparator={true}
                  allowNegative={false}
                  value={this.state.expenseAmt}
                  onChange={e => {
                    this.setState({ expenseAmt: e.target.value });
                  }}
                />
              </FormGroup>
              <ModalFooter className="p-0 mt-4">
                <Button type="submit" color="success" className="m-0">
                  {this.state.formState} Expense
                </Button>
              </ModalFooter>
            </Form>
          </ModalBody>
        </Modal>

        <Card>
          <CardBody className="px-5">
            {this.state.expenses.length > 0 ? (
              <>
                <div className="w-100 buttons-container mt-4 mb-3 d-flex justify-content-between">
                  <div>
                    {/* <Button className="mr-2" onClick={this.getAuthToken}>
                          Log auth token
                        </Button>
                        <Button
                          className="mx-2"
                          onClick={() => {
                            console.log(this.state);
                          }}
                        >
                          Log current state
                        </Button>

                        <Button
                          onClick={() => {
                            console.log(this.state.expenses);
                          }}
                        >
                          Log expenses list
                        </Button> */}
                    <h3 style={{ fontWeight: "700" }}>Expenses</h3>
                  </div>
                  <div className="d-flex">
                    <Button
                      className="mx-2 d-flex"
                      color="info"
                      onClick={() => {
                        this.setState({ expenseModal: true, formState: "Add" });
                      }}
                    >
                      <p className="m-0">Add</p>
                      <i
                        className="bx bx-add-to-queue"
                        style={{
                          marginLeft: "8px",
                          transform: "scale(1.4) translateY(1px)",
                        }}
                        aria-hidden="true"
                      ></i>
                    </Button>
                    <Button
                      className="mx-2 d-flex"
                      color="success"
                      onClick={this.importExpenses.bind(this)}
                    >
                      <p className="m-0">Import</p>
                      <i
                        className="bx bx-import"
                        style={{
                          marginLeft: "8px",
                          transform: "scale(1.4) translateY(1px)",
                        }}
                        aria-hidden="true"
                      ></i>
                    </Button>
                    <Button
                      className="mx-2 d-flex"
                      color="danger"
                      onClick={this.triggerSelectItems}
                    >
                      <p className="m-0">
                        {this.state.selectItems ? "Confirm" : "Delete"}
                      </p>
                      <i
                        className="bx bx-trash"
                        style={{
                          marginLeft: "8px",
                          transform: "scale(1.4) translateY(1px)",
                        }}
                        aria-hidden="true"
                      ></i>
                    </Button>
                    {this.state.selectItems ? (
                      <Button
                        className="mx-2"
                        onClick={() => {
                          this.setState({ selectItems: false });
                        }}
                      >
                        Cancel
                      </Button>
                    ) : null}
                  </div>
                </div>

                <div className="search-container mb-4 pb-2">
                  <Form>
                    <Row>
                      <Col lg="6" md="12" className="mt-3">
                        <FormGroup>
                          <Label for="expense-search">Search</Label>
                          <Input
                            type="text"
                            name="expense search"
                            id="expense-search"
                            autoComplete="off"
                            placeholder="Search by description"
                            onChange={this.handleExpenseSearch.bind(this)}
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="3" md="6" className="mt-3">
                        <FormGroup>
                          <Label for="expense-filter">Filter by Type</Label>
                          <Select
                            id="expense-filter"
                            styles={reactSelectOptions}
                            onChange={this.handleExpenseFilter.bind(this)}
                            options={[
                              {
                                value: "No Filter",
                                label: "No Filter",
                              },
                            ].concat(
                              categoryOptions.map(el => ({
                                value: el,
                                label: el,
                              }))
                            )}
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="3" md="6" className="mt-3">
                        <FormGroup>
                          <Label for="expense-sort">Sort</Label>
                          <Select
                            id="expense-sort"
                            styles={reactSelectOptions}
                            onChange={this.handleExpenseSort.bind(this)}
                            options={[
                              {
                                value: false,
                                label: "No sorting",
                              },
                              {
                                value: "A-HL",
                                label: "Amount:  High to Low",
                              },
                              {
                                value: "A-LH",
                                label: "Amount:  Low to High",
                              },
                              // {
                              //   value: "D-HL",
                              //   label: "Date:  Newest first",
                              // },
                              // {
                              //   value: "D-LH",
                              //   label: "Date:  Oldest First",
                              // },
                            ]}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </Form>
                </div>

                <PaginationProvider
                  pagination={paginationFactory(tableConfig.pageOptions)}
                  keyField="id"
                  columns={tableConfig.columns}
                  data={this.state.filteredExpensesList}
                >
                  {({ paginationProps, paginationTableProps }) => (
                    <ToolkitProvider
                      keyField="id"
                      data={this.state.filteredExpensesList}
                      columns={tableConfig.columns}
                      bootstrap4
                      search
                    >
                      {toolkitProps => (
                        <React.Fragment>
                          <div className="table-responsive">
                            <BootstrapTable
                              {...toolkitProps.baseProps}
                              {...paginationTableProps}
                              responsive
                              defaultSorted={tableConfig.defaultSorted}
                              sort={tableConfig.sort}
                              bordered={false}
                              striped={false}
                              selectRow={tableConfig.selectRow}
                              classes={
                                "table align-middle table-nowrap table-check"
                              }
                              rowClasses="transaction-table-row"
                              headerWrapperClasses={"table-light"}
                            />
                          </div>
                          <div className="pagination pagination-rounded justify-content-end">
                            <PaginationListStandalone {...paginationProps} />
                          </div>
                        </React.Fragment>
                      )}
                    </ToolkitProvider>
                  )}
                </PaginationProvider>
              </>
            ) : (
              <div className="d-flex align-items-center flex-column py-4">
                <h2 style={{ fontWeight: "700" }} className="mb-5">
                  Expenses
                </h2>
                <img
                  src={noData}
                  style={{ maxWidth: "450px", width: "100%", opacity: "90%" }}
                  alt="No credit cards added"
                />
                <p
                  className="data-placeholder-text mt-5 mb-1"
                  style={{ fontWeight: "600", fontSize: "1.2rem" }}
                >
                  No data
                </p>
                <p
                  className="data-placeholder-text"
                  style={{
                    color: "#8d8d8d",
                    fontSize: "15px",
                    fontWeight: "500",
                  }}
                >
                  Let&apos;s get started by adding an expense
                </p>
                <Button
                  className="mx-2 d-flex"
                  color="primary"
                  onClick={() => {
                    this.setState({ expenseModal: true, formState: "Add" });
                  }}
                >
                  <p className="m-0">Add Expense</p>
                  <i
                    className="bx bx-add-to-queue"
                    style={{
                      marginLeft: "8px",
                      transform: "scale(1.4) translateY(1px)",
                    }}
                    aria-hidden="true"
                  ></i>
                </Button>
              </div>
            )}
          </CardBody>
        </Card>
      </React.Fragment>
    );
  }
}

ExpensesTable.propTypes = {
  expenses: PropTypes.array,
  onGetExpenses: PropTypes.func,
  onAddNewExpense: PropTypes.func,
  onDeleteExpense: PropTypes.func,
  onUpdateExpense: PropTypes.func,
  successModal: PropTypes.bool,
  failModal: PropTypes.bool,
  onCloseModals: PropTypes.func,
  apiStatusMessage: PropTypes.object,
  dateRange: PropTypes.any,
  onImportExpense: PropTypes.func,
  onGetBanks: PropTypes.func,
  bankAccounts: PropTypes.array,
  resetDateRange: PropTypes.func,
};

const mapStateToProps = state => ({
  expenses: state.expenses.expenses,
  successModal: state.expenses.successModal,
  failModal: state.expenses.failModal,
  apiStatusMessage: state.expenses.apiStatusMessage,
  dateRange: state.Common.dateRange,
  bankAccounts: state.banks.bankAccounts,
});

const mapDispatchToProps = dispatch => ({
  onGetExpenses: () => dispatch(getExpenses()),
  onAddNewExpense: expenseData => dispatch(addNewExpense(expenseData)),
  onUpdateExpense: expenseData => dispatch(updateExpense(expenseData)),
  onDeleteExpense: expenseData => dispatch(deleteExpense(expenseData)),
  onImportExpense: bankData => dispatch(importExpenses(bankData)),
  onCloseModals: () => dispatch(closeModals()),
  onGetBanks: () => dispatch(getBanks()),
  resetDateRange: () => dispatch(resetDateRange()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ExpensesTable));
