import moment from "moment";

export function isListUpdated(data, prevData) {
  if (data.length !== prevData.length) {
    return true;
  } else {
    var flag = false;
    for (var i = 0; i < data.length; i++) {
      if (data[i] !== prevData[i]) {
        flag = true;
      }
    }

    return flag;
  }
}

export function isDateRangeUpdated(data, prevData) {
  return data !== prevData;
}

export function filterDataFromDateRange(dateRange, data) {
  return data.filter(
    item =>
      moment(item.txn_date).isSameOrAfter(dateRange.startDate) &&
      moment(item.txn_date).isSameOrBefore(dateRange.endDate)
  );
}

export function ArrSum(arr, key) {
  let sum = 0;
  for (let i = 0; i < arr.length; i++) {
    sum += parseFloat(arr[i][key]);
  }

  console.log("Array sun is", sum);
  return sum;
}
