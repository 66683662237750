// MODAL
export const SUCCESS_MODAL = "SUCCESS_MODAL";
export const FAIL_MODAL = "FAIL_MODAL";
export const CLOSE_MODALS = "CLOSE_MODALS";
export const API_ERROR = "API_ERROR";

// API CALLS
export const GET_BANKS = "GET_BANKS";
export const GET_BANKS_SUCCESS = "GET_BANKS_SUCCESS";
export const GET_BANK_TRANSACTIONS = "GET_BANK_TRANSACTIONS";
export const GET_BANK_TRANSACTIONS_SUCCESS = "GET_BANK_TRANSACTIONS_SUCCESS";
export const ADD_NEW_BANK = "ADD_NEW_BANK";
export const DELETE_BANK = "DELETE_BANK";
