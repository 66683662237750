// MODAL
export const SUCCESS_MODAL = "SUCCESS_MODAL";
export const FAIL_MODAL = "FAIL_MODAL";
export const CLOSE_MODALS = "CLOSE_MODALS";
export const API_ERROR = "API_ERROR";

// API CALLS
export const GET_EXPENSES = "GET_EXPENSES";
export const GET_EXPENSES_SUCCESS = "GET_EXPENSES_SUCCESS";
export const ADD_NEW_EXPENSE = "ADD_NEW_EXPENSE";
export const UPDATE_EXPENSE = "UPDATE_EXPENSE";
export const DELETE_EXPENSE = "DELETE_EXPENSE";
export const IMPORT_EXPENSES = "IMPORT_EXPENSES";
