import React, { useState, useEffect } from "react";
import Toast from "react-bootstrap/Toast";
import Button from "react-bootstrap/Button";
import ToastContainer from "react-bootstrap/ToastContainer";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useSelector } from "react-redux";

function Toasts() {
  const [show, setShow] = useState(false);
  const dateRange = useSelector(state => state.Common.dateRange);
  useEffect(() => {
    setShow(true);
  }, [dateRange]);
  return (
    <div>
      <ToastContainer
        position="bottom-start"
        style={{ marginLeft: "30px", marginBottom: "24px", zIndex:'999999999' }}
      >
        <Toast
          onClose={() => setShow(false)}
          show={show}
          delay={8000}
          style={{ borderRadius: "4px" }}
        >
          <Toast.Header
            style={{
              backgroundColor: "rgba(85, 110, 230, 0.25)",
              color: "#556EE6",
            }}
          >
            <img
              src="holder.js/20x20?text=%20"
              className="rounded me-2"
              alt=""
            />
            <strong className="me-auto">Date Range Updated</strong>
          </Toast.Header>
          {dateRange ? (
            <Toast.Body style={{ backgroundColor: "#556EE6", color: "#fff" }}>
              Currently showing data from{" "}
              {dateRange.startDate.toString().slice(4, 10)} through{" "}
              {dateRange.endDate.toString().slice(4, 10)}
            </Toast.Body>
          ) : (
            <Toast.Body style={{ backgroundColor: "#556EE6", color: "#fff" }}>
              Date range successfully resetted
            </Toast.Body>
          )}
        </Toast>
      </ToastContainer>
    </div>
  );
}

export default Toasts;
