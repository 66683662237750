import React, { Component } from "react";
import PropTypes from "prop-types";
import { Card, CardBody, CardTitle, Row, Col, Button } from "reactstrap";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
} from "react-bootstrap-table2-paginator";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import { Link } from "react-router-dom";
import NumberFormat from "react-number-format";
import CreditCard from "components/Banks/CreditCard";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { post } from "../../services/helpers/api_helper";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { getBanks, getBankTransactions, deleteBank } from "store/actions";
import { ArrSum, isListUpdated } from "services/helpers/funcions";
import SweetAlert from "react-bootstrap-sweetalert";
import moment from "moment";
import noCards from "../../assets/images/custom/no-cards.svg";

class BankAccounts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      bankAccounts: [],
      bankTransactions: [],
      selectedAccount: false,
      confirmDeleteModal: false,
      deleteBankName: "",
      deleteBankID: false,
      bankExpenses: 0,
      bankRevenue: 0,
    };
  }

  componentDidMount() {
    const { onGetBanks } = this.props;
    onGetBanks();
  }

  componentDidUpdate(prevProps) {
    const { bankAccounts, bankTransactions } = this.props;

    if (isListUpdated(bankAccounts, prevProps.bankAccounts)) {
      this.setState({ bankAccounts });
    }

    if (isListUpdated(bankTransactions, prevProps.bankTransactions)) {
      this.setState({ bankTransactions: bankTransactions });
      this.calcExpensesRevenue(bankTransactions);
    }
  }

  calcExpensesRevenue(bankTransactions) {
    let bankExpensesList = bankTransactions.filter(el => {
      return el.amount[0] === "-";
    });

    let bankRevenueList = bankTransactions.filter(el => {
      return el.amount[0] !== "-";
    });

    let bankExpenses = ArrSum(bankExpensesList, "amount");
    let bankRevenue = ArrSum(bankRevenueList, "amount");

    this.setState({ bankExpenses, bankRevenue });
  }

  handleAccountTransactions(accountData) {
    const { onGetBankTransactions } = this.props;
    this.setState({ selectedAccount: accountData });

    onGetBankTransactions(accountData.id);
  }

  handleDeleteAccount(accountData) {
    console.log("Delete account info", accountData);
    this.setState({
      confirmDeleteModal: true,
      deleteBankName: accountData.bank_name,
      deleteBankID: accountData.id,
    });
  }

  deleteBank() {
    const { onDeleteBank } = this.props;
    console.log("This bank will be deleted", this.state.deleteBankID);

    onDeleteBank({
      bank_id: this.state.deleteBankID,
    });

    this.setState({ confirmDeleteModal: false });
  }

  render() {
    const tellerConnect = window.TellerConnect.setup({
      applicationId: "app_nk69gr2klvs9sg5eha000",
      onInit: function () {
        // console.log("Teller Connect has initialized");
      },
      onSuccess: function (enrollment) {
        post("/banks/add", enrollment).then(res => {
          return new Promise((resolve, reject) => {
            setTimeout(() => {
              if (res) {
                console.log("Success");
                resolve(res);
              } else {
                reject("Error: Cannot link bank");
              }
            });
          });
        });
      },
      onExit: function () {
        console.log("User closed Teller Connect");
      },
    });

    const pageOptions = {
      sizePerPage: 10,
      totalSize: this.state.bankTransactions.length, // replace later with size(Order),
      custom: true,
    };

    const defaultSorted = [
      {
        dataField: "orderId",
        order: "desc",
      },
    ];

    const tableColumns = [
      {
        text: "id",
        dataField: "id",
        sort: true,
        hidden: true,
        formatter: (cellContent, user) => <>{order.id}</>,
      },
      {
        dataField: "description",
        text: "Description",
      },
      {
        dataField: "txn_date",
        text: "Date",
        formatter: (cellContent, row) =>
          moment(row.date).format("DD / MM / YYYY"),
      },
      {
        dataField: "sub_category",
        text: "Type",
        formatter: (cellContent, row) =>
          row.details.category.charAt(0).toUpperCase() +
          row.details.category.slice(1),
      },
      {
        dataField: "amount",
        text: "Amount",
        formatter: (cellContent, row) => (
          <Link to="#" className="text-body fw-bold">
            <NumberFormat
              thousandsGroupStyle="thousand"
              prefix="$ "
              decimalSeparator="."
              displayType="text"
              value={row.amount.substring(1)}
              thousandSeparator={true}
            />
          </Link>
        ),
      },
    ];

    return (
      <React.Fragment>
        {this.state.confirmDeleteModal ? (
          <SweetAlert
            title="Are you sure?"
            warning
            showCancel
            confirmButtonText="Yes, delete it!"
            confirmBtnBsStyle="success"
            cancelBtnBsStyle="danger"
            onConfirm={() =>
              this.setState({
                confirmDeleteModal: false,
              })
            }
            customButtons={
              <>
                <Button
                  onClick={() =>
                    this.setState({
                      confirmDeleteModal: false,
                    })
                  }
                  className="mx-2"
                >
                  Cancel
                </Button>
                <Button
                  onClick={this.deleteBank.bind(this)}
                  className="mx-2"
                  color="danger"
                >
                  Delete
                </Button>
              </>
            }
          >
            <p style={{ fontWeight: 500, margin: "15px 0" }}>
              Your{" "}
              <span style={{ fontWeight: 700 }}>
                {this.state.deleteBankName}
              </span>{" "}
              bank account will be deleted
            </p>
          </SweetAlert>
        ) : null}

        <Card>
          <CardBody className="px-5 py-4">
            {this.state.bankAccounts.length > 0 ? (
              <>
                <div className="d-flex align-items-center justify-content-between">
                  <h3 style={{ fontWeight: "700" }} className="py-2">
                    Bank Accounts
                  </h3>
                  <button
                    onClick={() => tellerConnect?.open()}
                    className="btn btn-primary mx-2 d-flex"
                  >
                    <p className="m-0">Add Bank</p>
                    <i
                      className="bx bxs-bank"
                      style={{
                        marginLeft: "8px",
                        transform: "scale(1.4) translateY(1px)",
                      }}
                      aria-hidden="true"
                    ></i>
                  </button>
                </div>
                <div className="credit-card-slider-wrapper">
                  <Slider
                    arrows={true}
                    dots={true}
                    speed={500}
                    infinite={true}
                    slidesToShow={3}
                    slidesToScroll={1}
                    className="credit-card-slider"
                  >
                    {this.state.bankAccounts.map((el, index) => {
                      let bg = index + 1;
                      if (index > 4) {
                        bg = 4;
                      }

                      return (
                        <CreditCard
                          key={index}
                          className={"bg" + bg}
                          cardNumber={"************" + el.last_four}
                          bankName={el.bank_name}
                          accountData={el}
                          handleGetTransaction={this.handleAccountTransactions.bind(
                            this
                          )}
                          handleDelete={this.handleDeleteAccount.bind(this)}
                          // expiryDate="10/25"
                          // CVV={789}
                        />
                      );
                    })}
                  </Slider>
                </div>
              </>
            ) : (
              <div className="d-flex align-items-center flex-column py-4">
                <h2 style={{ fontWeight: "700" }} className="mb-5">
                  Bank Accounts
                </h2>
                <img
                  src={noCards}
                  style={{ maxWidth: "450px", width: "100%", opacity: "90%" }}
                  alt="No credit cards added"
                />
                <p
                  style={{
                    fontWeight: 600,
                    fontSize: "1rem",
                    margin: "50px 0 20px 0",
                    color: "#6a6a6a",
                  }}
                >
                  Add a bank account to import your transactions
                </p>
                <Button
                  onClick={() => tellerConnect?.open()}
                  color="primary"
                  className="d-flex"
                >
                  <p className="m-0">Add Bank</p>
                  <i
                    className="bx bxs-bank"
                    style={{
                      marginLeft: "8px",
                      transform: "scale(1.4) translateY(1px)",
                    }}
                    aria-hidden="true"
                  ></i>
                </Button>
              </div>
            )}
          </CardBody>
        </Card>

        {/* Expenses table */}
        {this.state.selectedAccount ? (
          <Card>
            <CardBody className="px-5 pt-3">
              <div className="d-flex mb-5">
                <CreditCard
                  className={
                    "hover map no-eye bg" + this.state.selectedAccount.id
                  }
                  cardNumber={
                    "************" + this.state.selectedAccount.last_four
                  }
                  bankName={this.state.selectedAccount.bank_name}
                  // expiryDate="10/25"
                  // CVV={789}
                />

                <div className="px-5">
                  <div className="d-flex align-items-center my-4 pb-3">
                    <p className="h3 m-0" style={{ fontWeight: "700" }}>
                      {this.state.selectedAccount.bank_name}
                    </p>
                    {/* <button className="square-btn" style={{ marginLeft: "20px" }}>
                    <i className="fas fa-pencil-alt" aria-hidden="true"></i>
                  </button>
                  <button className="square-btn mx-3">
                    <i
                      className="far fa-trash-alt
"
                      aria-hidden="true"
                    ></i>
                  </button> */}
                  </div>
                  <div className="d-flex">
                    <Card className="bank-page-card">
                      <CardBody>
                        <p className="h5" style={{ fontWeight: "700" }}>
                          Revenue
                        </p>
                        <NumberFormat
                          thousandsGroupStyle="thousand"
                          className="h4"
                          prefix="$ "
                          decimalSeparator="."
                          displayType="text"
                          type="text"
                          decimalScale={2}
                          thousandSeparator={true}
                          allowNegative={false}
                          value={this.state.bankRevenue}
                        />
                      </CardBody>
                    </Card>
                    <Card className="bank-page-card mx-4">
                      <CardBody>
                        <p className="h5" style={{ fontWeight: "700" }}>
                          Expenses
                        </p>
                        <NumberFormat
                          thousandsGroupStyle="thousand"
                          className="h4"
                          prefix="$ "
                          decimalSeparator="."
                          displayType="text"
                          type="text"
                          decimalScale={2}
                          thousandSeparator={true}
                          allowNegative={false}
                          value={this.state.bankExpenses}
                        />
                      </CardBody>
                    </Card>
                  </div>
                </div>
              </div>
              <PaginationProvider
                pagination={paginationFactory(pageOptions)}
                keyField="id"
                columns={tableColumns}
                data={this.state.bankTransactions}
              >
                {({ paginationProps, paginationTableProps }) => (
                  <ToolkitProvider
                    keyField="id"
                    data={this.state.bankTransactions}
                    columns={tableColumns}
                    bootstrap4
                    search
                  >
                    {toolkitProps => (
                      <React.Fragment>
                        <div className="table-responsive">
                          <BootstrapTable
                            {...toolkitProps.baseProps}
                            {...paginationTableProps}
                            responsive
                            defaultSorted={defaultSorted}
                            bordered={false}
                            striped={false}
                            // selectRow={selectRow}
                            classes={
                              "table align-middle table-nowrap table-check"
                            }
                            headerWrapperClasses={"table-light"}
                          />
                        </div>
                        <div className="pagination pagination-rounded justify-content-end">
                          <PaginationListStandalone {...paginationProps} />
                        </div>
                      </React.Fragment>
                    )}
                  </ToolkitProvider>
                )}
              </PaginationProvider>
            </CardBody>
          </Card>
        ) : null}
      </React.Fragment>
    );
  }
}

BankAccounts.propTypes = {
  bankAccounts: PropTypes.any,
  bankTransactions: PropTypes.any,
  failModal: PropTypes.any,
  apiStatusMessage: PropTypes.any,
  dateRange: PropTypes.any,
  onGetBanks: PropTypes.func,
  onGetBankTransactions: PropTypes.func,
  onDeleteBank: PropTypes.func,
  onCloseModals: PropTypes.func,
};

const mapStateToProps = state => ({
  bankAccounts: state.banks.bankAccounts,
  bankTransactions: state.banks.bankTransactions,
  failModal: state.banks.failModal,
  apiStatusMessage: state.banks.apiStatusMessage,
  dateRange: state.Common.dateRange,
});

const mapDispatchToProps = dispatch => ({
  onGetBanks: () => dispatch(getBanks()),
  onGetBankTransactions: data => dispatch(getBankTransactions(data)),
  onDeleteBank: data => dispatch(deleteBank(data)),
  onCloseModals: () => dispatch(closeModals()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(BankAccounts));
