import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

// Reactstrap
import { Dropdown, DropdownMenu, DropdownToggle } from "reactstrap";

// Date picker
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { DateRangePicker } from "react-date-range";
import moment from "moment";

import { setDateRange } from "store/actions";

class DatePicker extends Component {
  // static propTypes = {
  //   dateRangeObj: PropTypes.object,
  // };

  state = {
    datePickerOpen: false,
    defaultText: true,
    currentDateRange: {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  };

  componentDidMount() {
    if (this.props.dateRange && this.state.defaultText) {
      this.setState({
        defaultText: false,
        currentDateRange: this.props.dateRange,
      });
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props !== prevProps) {
      if (this.props.dateRange) {
        this.setState({
          currentDateRange: this.props.dateRange,
          defaultText: false,
        });
      }
    }

    // if (this.props.dateRange && this.state.defaultText) {
    //   this.setState({ defaultText: false });
    // }

    // console.log("Date picker state", this.props.dateRange);
  }

  handleDateRange(range) {
    const { onSetDateRange } = this.props;

    onSetDateRange(range.selection);
    // this.setState({ defaultText: false });
  }

  render() {
    return (
      <div className="date-picker">
        <Dropdown
          className="date-picker-toggle"
          isOpen={this.state.datePickerOpen}
          toggle={() => {
            this.setState({
              datePickerOpen: !this.state.datePickerOpen,
            });
          }}
        >
          <DropdownToggle
            className="btn header-item noti-icon d-flex align-items-center"
            tag="button"
            style={{ borderRadius: "500px" }}
          >
            {this.state.defaultText ? (
              <p className="m-0 date-text">Click to select a date range</p>
            ) : (
              <p className="m-0 date-text">
                Displaying data from&nbsp;&nbsp;
                <span>
                  {moment(this.state.currentDateRange.startDate).format(
                    "Do MMM"
                  )}
                </span>
                &nbsp; to &nbsp;
                <span>
                  {moment(this.state.currentDateRange.endDate).format("Do MMM")}
                </span>
              </p>
            )}
            <i
              className="far fa-calendar-alt font-size-20"
              style={{ marginLeft: "10px" }}
            />
          </DropdownToggle>
          {this.state.datePickerOpen ? (
            <DropdownMenu className="datepicker-dropdown-menu">
              <DateRangePicker
                className="datepicker"
                ranges={[this.state.currentDateRange]}
                onChange={this.handleDateRange.bind(this)}
                maxDate={new Date()}
              />
            </DropdownMenu>
          ) : null}
        </Dropdown>
      </div>
    );
  }
}

DatePicker.propTypes = {
  expenses: PropTypes.any,
  dateRange: PropTypes.any,
  onSetDateRange: PropTypes.func,
  reference: PropTypes.object,
};

const mapStateToProps = state => ({
  expenses: state.expenses.expenses,
  dateRange: state.Common.dateRange,
});

const mapDispatchToProps = dispatch => ({
  onSetDateRange: data => dispatch(setDateRange(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DatePicker);
