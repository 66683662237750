import axios from "axios";
import authHeader from "./auth_helper";

const API_URL = process.env.REACT_APP_BACKEND_API_URL;

const axiosApi = axios.create({
  baseURL: API_URL,
});

axiosApi.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (isTokenExpired()) {
      return;
    }
    return Promise.reject(error);
  }
);

function isTokenExpired() {
  return false;
}

export async function get(url, config = {}) {
  return await axiosApi
    .get(url, { headers: authHeader(), ...config })
    .then(response => response.data);
}

export async function post(url, data, config = {}) {
  return axiosApi
    .post(url, data, { headers: authHeader(), ...config })
    .then(response => response.data);
}

export async function put(url, data, config = {}) {
  return axiosApi
    .put(url, data, { headers: authHeader(), ...config })
    .then(response => response.data);
}

export async function del(url, data, config = {}) {
  return await axiosApi
    .delete(url, { headers: authHeader(), data, ...config })
    .then(response => response.data);
}
