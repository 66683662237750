import { all, fork } from "redux-saga/effects";

//public
import expensesSaga from "./expenses/saga";
import revenueSaga from "./revenue/saga";

import AccountSaga from "./auth/register/saga";
import AuthSaga from "./auth/login/saga";

import BanksSaga from "./banks/saga";

export default function* rootSaga() {
  yield all([
    fork(expensesSaga),
    fork(revenueSaga),
    fork(AccountSaga),
    fork(AuthSaga),
    fork(BanksSaga),
  ]);
}
