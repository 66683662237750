import { call, put, takeEvery } from "redux-saga/effects";

// Ecommerce Redux States
import {
  GET_REVENUE,
  ADD_NEW_REVENUE,
  DELETE_REVENUE,
  UPDATE_REVENUE,
  IMPORT_REVENUE,
} from "./actionTypes";

import {
  getRevenueSuccess,
  successModal,
  failModal,
  apiStatus,
} from "./actions";

import {
  getRevenue,
  addNewRevenue,
  updateRevenue,
  deleteRevenue,
  importNewRevenue,
} from "services/revenue";

function* onGetRevenue() {
  try {
    const response = yield call(getRevenue);
    yield put(getRevenueSuccess(response));
  } catch (error) {
    if (error.response.status !== 400) {
      yield put(
        apiStatus({
          heading: "Error",
          message: "Could not fetch revenue, please reload the page",
        })
      );
      yield put(failModal());
    }
  }
}

function* onAddNewRevenue({ payload: revenueData }) {
  try {
    yield call(addNewRevenue, revenueData);
    yield put(
      apiStatus({
        heading: "Revenue added",
        message: "Revenue added successfully",
      })
    );
    yield put(successModal());
  } catch (error) {
    yield put(
      apiStatus({
        heading: "Error",
        message: "Could not add revenue, please try again",
      })
    );
    yield put(failModal());
  }
}

function* onUpdateRevenue({ payload: revenueData }) {
  try {
    yield call(updateRevenue, revenueData);
    yield put(
      apiStatus({
        heading: "Revenue Updated!",
        message: "Revenue Updated successfully",
      })
    );
    yield put(successModal());
  } catch (error) {
    yield put(
      apiStatus({
        heading: "Error",
        message: "Could not update revenue, please try again",
      })
    );
    yield put(failModal());
  }
}

function* onDeleteRevenue({ payload: revenueData }) {
  try {
    yield call(deleteRevenue, revenueData);
    yield put(
      apiStatus({
        heading: "Revenue Deleted!",
        message: "Revenue deleted successfully",
      })
    );
    yield put(successModal());
  } catch (error) {
    yield put(
      apiStatus({
        heading: "Error",
        message: "Could not delete revenue, please try again",
      })
    );
    yield put(failModal());
  }
}

function* onImportRevenue({ payload: data }) {
  try {
    for (let i = 0; i < data.length; i++) {
      yield call(importNewRevenue, data[i].id);
    }
    yield put(
      apiStatus({
        heading: "Revenue Imported!",
        message: "Revenue imported successfully",
      })
    );
    yield put(successModal());
  } catch (error) {
    console.log("REVENUE IMPORT ERROR", error);
    yield put(
      apiStatus({
        heading: "Error",
        message: "Could not import revenue, please try again",
      })
    );
    yield put(failModal());
  }
}

function* revenueSaga() {
  yield takeEvery(GET_REVENUE, onGetRevenue);
  yield takeEvery(ADD_NEW_REVENUE, onAddNewRevenue);
  yield takeEvery(UPDATE_REVENUE, onUpdateRevenue);
  yield takeEvery(DELETE_REVENUE, onDeleteRevenue);
  yield takeEvery(IMPORT_REVENUE, onImportRevenue);
}

export default revenueSaga;
