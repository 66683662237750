import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link, withRouter } from "react-router-dom";
import classname from "classnames";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Row,
  Col,
  Button,
  Collapse,
  UncontrolledDropdown,
} from "reactstrap";

//i18n
import { withTranslation } from "react-i18next";

class Navbar extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    let matchingMenuItem = null;
    const ul = document.getElementById("navigation");
    const items = ul.getElementsByTagName("a");
    for (let i = 0; i < items.length; ++i) {
      if (this.props.location.pathname === items[i].pathname) {
        matchingMenuItem = items[i];
        break;
      }
    }
    if (matchingMenuItem) {
      this.activateParentDropdown(matchingMenuItem);
    }
  }

  activateParentDropdown = item => {
    item.classList.add("active");
    const parent = item.parentElement;
    if (parent) {
      parent.classList.add("active"); // li
      const parent2 = parent.parentElement;
      parent2.classList.add("active"); // li
      const parent3 = parent2.parentElement;
      if (parent3) {
        parent3.classList.add("active"); // li
        const parent4 = parent3.parentElement;
        if (parent4) {
          parent4.classList.add("active"); // li
          const parent5 = parent4.parentElement;
          if (parent5) {
            parent5.classList.add("active"); // li
            const parent6 = parent5.parentElement;
            if (parent6) {
              parent6.classList.add("active"); // li
            }
          }
        }
      }
    }
    return false;
  };

  toggleDatePicker() {
    this.setState(prevState => ({
      datePickerOpen: !prevState.datePickerOpen,
    }));
  }

  render() {
    return (
      <React.Fragment>
        <div className="topnav">
          <div className="container-fluid">
            <nav
              className="navbar navbar-light navbar-expand-lg topnav-menu"
              id="navigation"
            >
              <Collapse
                isOpen={this.props.menuOpen}
                className="navbar-collapse justify-content-between"
                id="topnav-menu-content"
              >
                <ul className="navbar-nav ">
                  <li className="nav-item dropdown">
                    <Link
                      className="nav-link dropdown-toggle arrow-none d-flex align-items-center"
                      // onClick={e => {
                      //   e.preventDefault();
                      //   this.setState({ isDashboard: !this.state.isDashboard });
                      // }}
                      to="/home"
                    >
                      <i className="bx bx-home-circle me-1" />
                      <p className="m-0">{this.props.t("Home")}</p>
                      {/* {this.props.menuOpen} */}
                    </Link>
                    {/* <div
                      className={classname("dropdown-menu", {
                        show: this.state.isDashboard,
                      })}
                    >
                      <Link to="/dashboard" className="dropdown-item">
                        {this.props.t("Defaullllllllllt")}
                      </Link>
                      <Link to="/dashboard-saas" className="dropdown-item">
                        {this.props.t("Saas")}
                      </Link>
                      <Link to="/dashboard-crypto" className="dropdown-item">
                        {this.props.t("Crypto")}
                      </Link>
                      <Link to="/dashboard-blog" className="dropdown-item">
                        {this.props.t("Blog")}
                      </Link>
                    </div> */}
                  </li>

                  <li className="nav-item dropdown expenses-tab">
                    <Link
                      to="/expenses"
                      className="nav-link dropdown-toggle arrow-none d-flex"
                    >
                      <i className="bx bx-dollar-circle pt-1 me-1" />
                      <p className="m-0">{this.props.t("Expenses")}</p>
                    </Link>
                  </li>

                  <li className="nav-item dropdown revenue-tab">
                    <Link
                      to="/revenue"
                      className="nav-link dropdown-toggle arrow-none d-flex"
                    >
                      <i className="bx bx-wallet-alt pt-1 me-1" />
                      <p className="m-0">{this.props.t("Revenue")}</p>
                    </Link>
                  </li>

                  <li className="nav-item dropdown banks-tab">
                    <Link
                      to="/bank-accounts"
                      className="nav-link dropdown-toggle arrow-none d-flex"
                    >
                      <i className="bx bxs-bank pt-1 me-1" />
                      <p className="m-0">{this.props.t("Bank Accounts")}</p>
                    </Link>
                  </li>
                </ul>
              </Collapse>
            </nav>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

Navbar.propTypes = {
  location: PropTypes.object,
  menuOpen: PropTypes.any,
  t: PropTypes.any,
};

export default withRouter(withTranslation()(Navbar));
