import React, { Component } from "react";
import PropTypes from "prop-types";
import { Card } from "reactstrap";
import moment from "moment";
import { dateFormat } from "constants/global_variables";
import Graph from "./Graph";

class StackedColumnChart extends Component {
  constructor(props) {
    super(props);

    this.state = {
      expenseSeries: [],
      revenueSeries: [],
      x_axis: [],
      finalGraphData: [],
    };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props !== prevProps) {
      let expenseDataCondensed = this.condenseGraphData(
        this.props.expenseSeries
      );

      let revenueDataCondensed = this.condenseGraphData(
        this.props.revenueSeries
      );

      let finalGraphData = this.populateGraphData(
        expenseDataCondensed,
        revenueDataCondensed
      );

      this.setState({ finalGraphData: finalGraphData });
    }

    // console.log("FINAL GRAPH DATA", this.state.finalGraphData);
  }

  condenseGraphData(data) {
    let i = 0;
    let j = 0;

    let listLen = data.length;

    // Getting unique dates
    let datesList = [];
    for (i = 0; i < listLen; i++) {
      if (!datesList.includes(data[i].txn_date)) {
        datesList.push(data[i].txn_date);
      }
    }

    // Adding data of same dates
    let dataPerDay = [];
    for (i = 0; i < datesList.length; i++) {
      let currentDateData = 0;
      let descArr = [];

      for (j = 0; j < data.length; j++) {
        if (moment(datesList[i]).isSame(data[j].txn_date)) {
          currentDateData += data[j].amount;
          descArr.push(data[j].description);
        }
      }

      dataPerDay.push({
        txn_date: datesList[i],
        amount: currentDateData,
        names: descArr,
      });
    }

    this.sortDates(dataPerDay);

    // console.log("Data per day", dataPerDay);

    return dataPerDay;
  }

  populateGraphData(expensesList, revenueList) {
    // console.log("Populate graph data function", expensesList, revenueList);

    // The oldest date on which data is present. This is where the graph starts.
    let startDate = new Date();

    if (
      (expensesList && expensesList.length !== 0) ||
      (revenueList && revenueList.length !== 0)
    ) {
      if (expensesList[0] && revenueList[0]) {
        // console.log(
        //   "Days difference",
        //   moment(expensesList[0].txn_date).diff(moment(revenueList[0].txn_date), "days")
        // );

        if (
          moment(expensesList[0].txn_date).diff(
            moment(revenueList[0].txn_date),
            "days"
          ) < 0
        ) {
          startDate = expensesList[0].txn_date;
        } else {
          startDate = revenueList[0].txn_date;
        }
      } else if (revenueList[0]) {
        startDate = revenueList[0].txn_date;
      } else if (expensesList[0]) {
        startDate = expensesList[0].txn_date;
      } else {
        console.warn("Wrong parameters");
      }
    }

    // console.log("START DATE", startDate);

    let finalDataList = [];

    while (
      moment().format(dateFormat) !== moment(startDate).format(dateFormat)
    ) {
      // console.log(count, moment(startDate).format(dateFormat));

      let expense = 0;
      let revenue = 0;

      expensesList.forEach(el => {
        if (
          moment(startDate).format(dateFormat) ===
          moment(el.txn_date).format(dateFormat)
        ) {
          expense = el.amount;
        }
      });

      revenueList.forEach(el => {
        if (
          moment(startDate).format(dateFormat) ===
          moment(el.txn_date).format(dateFormat)
        ) {
          revenue = el.amount;
        }
      });

      finalDataList.push({
        date: moment(startDate).format("YYYY-MM-DD"),
        expense: expense,
        revenue: revenue,
      });

      startDate = moment(startDate).add(1, "days");
    }

    return finalDataList;
  }

  sortDates = data =>
    data.sort((left, right) =>
      moment.utc(left.txn_date).diff(moment.utc(right.txn_date))
    );

  render() {
    return (
      <div style={{ position: "relative" }}>
        <Graph chartData={this.state.finalGraphData} />
        <div className="legend-container">
          <div className="wrapper">
            <div className="box" style={{ backgroundColor: "#d0ab3f" }}></div>
            <p>Expenses</p>
          </div>
          <div className="wrapper">
            <div className="box" style={{ backgroundColor: "#5582e6" }}></div>
            <p>Revenue</p>
          </div>
        </div>
        <div className="hide-graph-logo"></div>
      </div>
    );
  }
}

StackedColumnChart.propTypes = {
  expenseSeries: PropTypes.any,
  revenueSeries: PropTypes.any,
  dateRange: PropTypes.any,
};

export default StackedColumnChart;
