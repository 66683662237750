import React, { useState } from "react";
import { Steps } from "intro.js-react";
import "intro.js/introjs.css";
import { useSelector } from "react-redux";
import "../../index.css"; //styles for tooltips

function onBoardTutorial() {
  const Account = useSelector(state => state.Account);

  const steps = [
    {
      intro: "Hi 👋, this is a quick tutorial of how to use Mezur.",
    },
    {
      element: ".account",
      intro: "This is a summary of your account.",
    },
    {
      element: ".monthly-expenses",
      intro:
        "This highlights important figures and notices related to your account.",
    },
    {
      element: ".summary",
      intro:
        "This shows you a summary of the cash going in and out of your business.",
    },
    {
      element: ".date-picker",
      intro: "You can filter all the data by selecting a date range.",
    },
    {
      element: ".date-reset",
      intro: "You can always return to viewing the last month.",
    },
    {
      element: ".expenses-tab",
      intro: "You can add, import and track expenses here.",
    },
    {
      element: ".revenue-tab",
      intro: "You can add, import and track revenue here.",
    },
    {
      element: ".banks-tab",
      intro:
        "Here, you can add bank accounts. Mezur will automatically import and classify all expenses and revenues.",
    },
    {
      intro: "That's it! Welcome to Mezur 👋. ",
    },
  ];
  const [stepsEnabled, setStepsEnabled] = useState(true);
  const [initialStep, setInitialStep] = useState(0);
  const onExit = () => {
    setStepsEnabled(false);
  };
  return (
    <div>
      {Account.isUserNew && (
        <Steps
          enabled={stepsEnabled}
          steps={steps}
          initialStep={initialStep}
          onExit={onExit}
        />
      )}
    </div>
  );
}

export default onBoardTutorial;
