import React, { Component } from "react";

class Loader extends Component {
  render() {
    return (
      <div className="loader-wrapper">
        <div className="profile-main-loader">
          <div className="loader">
            <svg className="circular-loader" viewBox="25 25 50 50">
              <circle
                className="loader-path"
                cx="50"
                cy="50"
                r="20"
                fill="none"
                stroke="#556ee6"
                // stroke-width="2"
              />
            </svg>
            <h2 className="mt-5">Loading...</h2>
          </div>
        </div>
      </div>
    );
  }
}

export default Loader;
