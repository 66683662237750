import { takeEvery, put, call } from "redux-saga/effects";

import { LOGIN_USER, LOGOUT_USER, GET_USER_DATA } from "./actionTypes";
import { loginUserFailed, updateUserData, logoutUserSuccess } from "./actions";

import { signIn, getUserData } from "../../../services/auth";

function* loginUser({ payload: { user, history } }) {
  try {
    const response = yield call(signIn, user);
    localStorage.setItem("authUser", JSON.stringify(response));

    const userData = yield call(getUserData);
    localStorage.setItem("userData", JSON.stringify(userData));
    yield put(updateUserData(userData));

    history.push("/home");
  } catch (error) {
    console.log("Login error", error);
    yield put(
      loginUserFailed(
        "Incorrect username/password combination. Please try again!"
      )
    );
  }
}

function* onGetUserData() {
  try {
    const userData = yield call(getUserData);
    yield put(updateUserData(userData));
  } catch (err) {
    console.log("Error while getting user data", err);
    yield put(updateUserData(err));
  }
}

function* logoutUser({ payload: { history } }) {
  try {
    localStorage.removeItem("authUser");
    history.push("/login");
    yield put(logoutUserSuccess(""));
  } catch (error) {
    console.log("Error logging out");
  }
}

function* authSaga() {
  yield takeEvery(LOGIN_USER, loginUser);
  yield takeEvery(LOGOUT_USER, logoutUser);
  yield takeEvery(GET_USER_DATA, onGetUserData);
}

export default authSaga;
