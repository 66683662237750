import {
  SUCCESS_MODAL,
  FAIL_MODAL,
  CLOSE_MODALS,
  API_ERROR,
  GET_EXPENSES_SUCCESS,
} from "./actionTypes";

const INIT_STATE = {
  expenses: [],
  successModal: false,
  failModal: false,
  apiStatusMessage: {
    heading: "Initial Heading",
    message: "Initial Message",
  },
};

const Expenses = (state = INIT_STATE, action) => {
  switch (action.type) {
    // MODAL CASES
    case SUCCESS_MODAL:
      return {
        ...state,
        successModal: true,
      };

    case FAIL_MODAL:
      return {
        ...state,
        failModal: true,
      };

    case CLOSE_MODALS:
      return {
        ...state,
        successModal: false,
        failModal: false,
      };

    case API_ERROR:
      return {
        ...state,
        apiStatusMessage: action.payload,
      };

    // API CALL CASES
    case GET_EXPENSES_SUCCESS:
      return {
        ...state,
        expenses: action.payload,
      };

    default:
      return state;
  }
};

export default Expenses;
