import React from "react";
import { Redirect } from "react-router-dom";

// Expenses
import Expenses from "pages/Expenses";

// Revenue
import Revenue from "pages/Revenue";

// Banks
import Banks from "pages/Banks";

// Authentication related pages
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
import Register from "../pages/Authentication/Register";

// Home
import Home from "../pages/Home/index";

//Account or User related stuff
import AccountSettings from "pages/Account/AccountSettings";

const authProtectedRoutes = [
  { path: "/home", component: Home },

  // Expenses
  { path: "/expenses", component: Expenses },

  // Revenue
  { path: "/revenue", component: Revenue },

  // Banks
  { path: "/bank-accounts", component: Banks },

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  { path: "/", exact: true, component: () => <Redirect to="/home" /> },

  { path: "/account-settings", exact: true, component: AccountSettings },
];

const publicRoutes = [
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/register", component: Register },
];

export { authProtectedRoutes, publicRoutes };
