import React, { Component } from "react";
import PropTypes from "prop-types";

import {
  Alert,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Form,
  Label,
} from "reactstrap";

// Redux
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import MetaTags from "react-meta-tags";
import logo from "../../assets/images/brand-assets/mezur-logo-large-light.png";

// actions
import { loginUserFailed, loginUser } from "../../store/actions";
import EmailInput from "components/Authentication/EmailInput";
import PasswordInput from "components/Authentication/PasswordInput";
import Loader from "components/Common/Loader";

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isEmailValidated: false,
      email: "",
      password: "",
    };

    // handleValidSubmit
    this.handleValidSubmit = this.handleValidSubmit.bind(this);
  }

  // handleValidSubmit
  handleValidSubmit(event) {
    event.preventDefault();

    let creds = {
      email: this.state.email,
      password: this.state.password,
    };

    this.props.loginUser(creds, this.props.history);
  }

  handleInput(value, key) {
    this.setState({ [key]: value });
  }

  render() {
    return (
      <React.Fragment>
        <MetaTags>
          <title>Mezur.ai | Login</title>
        </MetaTags>
        {this.props.loading ? <Loader /> : null}
        <div className="account-pages my-5 pt-sm-5">
          <Container>
            <Row className="justify-content-center">
              <Col md={8} lg={6} xl={5}>
                <Card className="overflow-hidden">
                  <div className="d-flex justify-content-center">
                    <img
                      src={logo}
                      width="60%"
                      className="p-5"
                      alt="Mezur logo"
                    />
                  </div>
                  <CardBody className="pt-0">
                    <div className="p-2">
                      <Form
                        className="form-horizontal"
                        onSubmit={this.handleValidSubmit}
                      >
                        {this.props.error && this.props.error ? (
                          <Alert color="danger">{this.props.error}</Alert>
                        ) : null}

                        <div className="mb-3">
                          <Label>Email</Label>
                          <EmailInput
                            updateInput={this.handleInput.bind(this)}
                          />
                        </div>

                        <div className="mb-3">
                          <Label>Password</Label>
                          <PasswordInput
                            updateInput={this.handleInput.bind(this)}
                          />
                        </div>
                        {/* <div className="form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id="remember-check"
                          />
                          <label
                            className="form-check-label"
                            htmlFor="remember-check"
                          >
                            Remember me
                          </label>
                        </div> */}

                        <div className="mt-4 d-grid">
                          <button
                            className="btn btn-primary btn-block"
                            type="submit"
                          >
                            {" "}
                            Log In{" "}
                          </button>
                        </div>
                      </Form>
                      {/* <div className="float-end mt-2">
                        <Link to="/forgot-password" className="text-muted">
                          Forgot password?
                        </Link>
                      </div> */}
                      <div className="mt-5 text-center">
                        <p>
                          Don&apos;t have an account ?{" "}
                          <a href="register" className="fw-medium text-primary">
                            {" "}
                            Signup now{" "}
                          </a>{" "}
                        </p>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

Login.propTypes = {
  error: PropTypes.any,
  history: PropTypes.object,
  loginUser: PropTypes.func,
  loading: PropTypes.bool,
};

const mapStateToProps = state => {
  const { error, loading } = state.Login;
  return { error, loading };
};

export default withRouter(connect(mapStateToProps, { loginUser })(Login));
