import { post, get } from "./helpers/api_helper";
import * as url from "./helpers/url_helper";

// Login Method
const signIn = data =>
  post(url.SIGN_IN, data).then(res => {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        if (res) {
          resolve(res);
        } else {
          reject([
            "Invalid username password combination, please try again or reset your password",
          ]);
        }
      });
    });
  });

// Get user data
const getUserData = () => get(url.CHECK_USER);

// Register New User
const signUp = data =>
  post(url.SIGN_UP, data).then(res => {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        if (res) {
          resolve(res);
        } else {
          reject("User registration error, please try again");
        }
      });
    });
  });

export { signIn, signUp, getUserData };
