// AUTHENTICATION
export const SIGN_IN = "/auth/signin";
export const SIGN_UP = "/auth/signup";
export const CHECK_USER = "/auth/check_user";

// EXPENSES
export const GET_EXPENSES = "/expenses/get";
export const ADD_EXPENSES = "/expenses/add";
export const DELETE_EXPENSES = "/expenses/delete";
export const UPDATE_EXPENSES = "/expenses/update";
export const IMPORT_EXPENSES = "/expenses/import/";

// REVENUE
export const GET_REVENUE = "/revenue/get";
export const ADD_REVENUE = "/revenue/add";
export const DELETE_REVENUE = "/revenue/delete";
export const UPDATE_REVENUE = "/revenue/update";
export const IMPORT_REVENUE = "/revenue/import/";

// BANKS
export const GET_BANKS = "/banks/get";
export const GET_TRANSACTIONS = "/banks/transactions/";
export const DELETE_BANK = "/banks/delete";
