import React, { Component } from "react";

import { Row, Col, Card, CardBody } from "reactstrap";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import NumberFormat from "react-number-format";
import avatar1 from "../../assets/images/users/avatar-1.jpg";
import profileImg from "../../assets/images/profile-img.png";
import { withRouter } from "react-router-dom";
import { getUserData } from "store/actions";
import { connect } from "react-redux";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

class WelcomeComp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userData: {},
      expenses: props.expenses,
    };
  }

  static propTypes = {
    amount: PropTypes.number,
    history: PropTypes.any,
    userData: PropTypes.any,
    onGetUserData: PropTypes.any,
    expenses: PropTypes.array
  };


  componentDidMount() {
    this.props.onGetUserData();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props !== prevProps) {
      this.setState({
        userData: this.props.userData,
      });
    }
  }

  render() {
    return (
      <React.Fragment>
        <Card className="overflow-hidden account">
          <div className="bg-primary bg-soft">
            <Row>
              <Col xs="7">
                <div className="text-primary p-3">
                  <h5 className="text-primary">Welcome Back !</h5>
                  <p>Mezur Dashboard</p>
                </div>
              </Col>
              <Col xs="5" className="align-self-end">
                <img src={profileImg} alt="" className="img-fluid" />
              </Col>
            </Row>
          </div>
          <CardBody className="pt-0">
            <Row>
              <Col sm="4">
                <div className="avatar-md profile-user-wid mb-4">
                  <img
                    src={`https://ui-avatars.com/api/?name=${this.props.userData.first_name}+${this.props.userData.last_name}`}
                    alt=""
                    className="img-thumbnail rounded-circle"
                  />
                </div>
                <h5
                  style={{ textTransform: "capitalize" }}
                  className="font-size-15 text-truncate"
                >{`${this.state.userData.first_name} ${this.state.userData.last_name}`}</h5>
                <p className="text-muted mb-0 text-truncate">Accountant</p>
              </Col>

              <Col sm="8">
                <div className="pt-4">
                  <Row>
                    <Col xs="6">
                      <h5 className="font-size-15">{this.state.expenses.length}</h5>
                      <p className="text-muted mb-0">Transactions</p>
                    </Col>
                    <Col xs="6">
                      <NumberFormat
                        thousandsGroupStyle="thousand"
                        className="h5 font-size-15"
                        prefix="$ "
                        placeholder="Enter the amount in USD"
                        decimalSeparator="."
                        displayType="text"
                        type="text"
                        thousandSeparator={true}
                        allowNegative={false}
                        value={this.props.amount}
                      />
                      <p className="text-muted mb-0 pt-1">Revenue</p>
                    </Col>
                  </Row>
                  <OverlayTrigger
                    // trigger="hover"
                    key={"bottom"}
                    placement={"bottom"}
                    overlay={
                      <Tooltip id={`tooltip-${"bottom"}`}>
                        This feature is currently disabled.
                      </Tooltip>
                    }
                  >
                    <div
                      className="mt-4 btn btn-primary btn-sm"
                      style={{ opacity: "50%" }}
                    >
                      {/* <Link to="" className="btn btn-primary btn-sm">
                      Account Settings{" "}
                      <i className="mdi mdi-arrow-right ms-1" />
                    </Link> */}
                      Account Settings{" "}
                      <i className="mdi mdi-arrow-right ms-1" />
                    </div>
                  </OverlayTrigger>
                </div>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  userData: state.Login.userData,
  expenses: state.expenses.expenses
});

const mapDispatchToProps = dispatch => ({
  onGetUserData: () => dispatch(getUserData()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(WelcomeComp));
