import React, { Component } from "react";

import { Row, Col, Card, CardBody, CardTitle } from "reactstrap";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import NumberFormat from "react-number-format";
// import ApexRadial from "./ApexRadial";
import ApexPieChart from "./ApexPieChart";
import { isListUpdated } from "services/helpers/funcions";
import { categoryOptions } from "constants/global_variables";
import { forEach } from "lodash";

class MonthlyExpenses extends Component {
  constructor(props) {
    super(props);
    this.state = {
      chartData: {
        series: [],
        labels: [],
      },
    };
  }

  componentDidUpdate(prevProps) {
    if (isListUpdated(this.props.expenses, prevProps.expenses)) {
      let chartData = this.generateChartData();

      // console.log("Monthly chart data", chartData);

      this.setState({ chartData });
    }
  }

  generateChartData() {
    let sum = categoryOptions.map(el => {
      let categorySum = 0;

      this.props.expenses.forEach(expense => {
        if (
          el.charAt(0).toLocaleLowerCase() + el.substring(1) ===
          expense.sub_category
        ) {
          categorySum += expense.amount;
        }
      });

      return categorySum;
    });

    let series = [];
    let labels = [];

    sum.forEach((el, index) => {
      if (el !== 0) {
        series.push(el);
        labels.push(categoryOptions[index]);
      }
    });

    return {
      series,
      labels,
    };
  }

  render() {
    const series = [44, 55, 13, 33];

    return (
      <React.Fragment>
        {" "}
        <Card className="monthly-expenses">
          <CardBody>
            <CardTitle className="mb-4 h4">Monthly Expenses</CardTitle>
            <Row>
              <Col sm="6">
                <p className="text-muted">This month</p>
                <NumberFormat
                  thousandsGroupStyle="thousand"
                  className="h3"
                  prefix="$ "
                  placeholder="Enter the amount in USD"
                  decimalSeparator="."
                  displayType="text"
                  type="text"
                  thousandSeparator={true}
                  allowNegative={false}
                  value={this.props.amount}
                />
                {/* <p className="text-muted mt-2">
                  <span className="text-success me-2">
                    {" "}
                    12% <i className="mdi mdi-arrow-up"></i>{" "}
                  </span>{" "}
                  From previous month
                </p> */}
                <div className="mt-4">
                  <Link to="/expenses" className="btn btn-primary btn-sm">
                    View More <i className="mdi mdi-arrow-right ms-1"></i>
                  </Link>
                </div>
              </Col>
              <Col sm="6">
                <div className="mt-4 mt-sm-0">
                  <ApexPieChart data={this.state.chartData} />
                </div>
              </Col>
            </Row>
            {/* <p className="text-muted mb-0">
              We craft digital, graphic and dimensional thinking.
            </p> */}
          </CardBody>
        </Card>
      </React.Fragment>
    );
  }
}

MonthlyExpenses.propTypes = {
  amount: PropTypes.number,
  expenses: PropTypes.array,
};

export default MonthlyExpenses;
