// MODAL
export const SUCCESS_MODAL = "SUCCESS_MODAL";
export const FAIL_MODAL = "FAIL_MODAL";
export const CLOSE_MODALS = "CLOSE_MODALS";
export const API_ERROR = "API_ERROR";

// API CALLS
export const GET_REVENUE = "GET_REVENUE";
export const GET_REVENUE_SUCCESS = "GET_REVENUE_SUCCESS";
export const ADD_NEW_REVENUE = "ADD_NEW_REVENUE";
export const UPDATE_REVENUE = "UPDATE_REVENUE";
export const DELETE_REVENUE = "DELETE_REVENUE";
export const IMPORT_REVENUE = "IMPORT_REVENUE";
