import { get, post, put, del } from "./helpers/api_helper";
import * as url from "./helpers/url_helper";

// EXPENSES

// Get expenses
const getExpenses = () => get(url.GET_EXPENSES);

// Add esxpense
const addNewExpense = data =>
  post(url.ADD_EXPENSES, data).then(res => {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        if (res) {
          resolve(res);
        } else {
          reject("Error: Cannot add expense");
        }
      });
    });
  });

// Delete expense
const deleteExpense = data =>
  del(url.DELETE_EXPENSES, data).then(res => {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        if (res) {
          resolve(res);
        } else {
          reject("Error: Cannot delete expense");
        }
      });
    });
  });

// Update expense
const updateExpense = expense =>
  put(url.UPDATE_EXPENSES + "/" + `${expense.id}`, expense.data).then(res => {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        if (res) {
          resolve(res);
        } else {
          reject("Error: Cannot update expense");
        }
      });
    });
  });

const importNewExpenses = bankID =>
  post(url.IMPORT_EXPENSES + bankID).then(res => {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        if (res) {
          resolve(res);
        } else {
          reject("Error: Cannot import expenses");
        }
      });
    });
  });

export {
  getExpenses,
  addNewExpense,
  deleteExpense,
  updateExpense,
  importNewExpenses,
};
