import React, { Component } from "react";
import PropTypes from "prop-types";

export default class CreditCard extends Component {
  static propTypes = {
    className: PropTypes.string,
    cardNumber: PropTypes.string,
    expiryDate: PropTypes.string,
    bankName: PropTypes.string,
    CVV: PropTypes.number,
    accountData: PropTypes.any,
    handleGetTransaction: PropTypes.any,
    handleDelete: PropTypes.any,
  };

  render() {
    return (
      <div>
        <div className={"credit-card-wrapper map" + " " + this.props.className}>
          <div className="card__front card__part">
            {/* <img
              className="card__front-square card__square"
              src="https://w7.pngwing.com/pngs/460/694/png-transparent-iphone-computer-icons-integrated-circuits-chips-subscriber-identity-module-sim-cards-electronics-text-rectangle.png"
            /> */}
            <div className="d-flex justify-content-end">
              {/* <img
                className="card__front-logo card__logo"
                src="https://www.seekpng.com/png/full/212-2128294_visa-logo-png-image-visa-logo-white-png.png"
              /> */}
              <button
                className="square-btn"
                onClick={() => {
                  this.props.handleGetTransaction(this.props.accountData);
                }}
              >
                <i className="fa fa-eye" aria-hidden="true"></i>
              </button>
              <button
                className="square-btn delete-btn"
                onClick={() => {
                  this.props.handleDelete(this.props.accountData);
                }}
              >
                <i className="bx bx-trash" aria-hidden="true"></i>
              </button>
            </div>
            <div className="d-flex justify-content-between mt-4">
              <div>
                <span className="card__label">Bank</span>
                <p className="card__info">{this.props.bankName}</p>
              </div>
              {/* <div>
                <span className="card__label">Expires</span>
                <p className="card__info">10/25</p>
              </div> */}
            </div>

            <p className="card_numer">{this.props.cardNumber}</p>
          </div>

          <div className="card__back card__part">
            <div className="card__black-line"></div>
            <div className="card__back-content">
              <div className="card__secret">
                <p className="card__secret--last">***</p>
              </div>
              {/* <img
                className="card__back-square card__square"
                src="https://w7.pngwing.com/pngs/460/694/png-transparent-iphone-computer-icons-integrated-circuits-chips-subscriber-identity-module-sim-cards-electronics-text-rectangle.png"
              />
              <img
                className="card__back-logo card__logo"
                src="https://pngimg.com/uploads/visa/visa_PNG30.png"
              /> */}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
