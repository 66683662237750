import React, { Component } from "react";
import ReactApexChart from "react-apexcharts";
import PropTypes from "prop-types";

class ApexPieChart extends Component {
  render() {
    const options = {
      chart: {
        type: "donut",
      },
      dataLabels: {
        enabled: false,
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              show: false,
            },
          },
        },
      ],
      legend: {
        show: false,
      },
      labels: this.props.data.labels,
    };

    return (
      <React.Fragment>
        {/* {console.log("Final chart data", this.props.data.series)} */}
        {this.props.data.series.length > 0 ? (
          <ReactApexChart
            options={options}
            series={this.props.data.series}
            type="donut"
            // data={[45, 90, 89]}
            height={180}
          />
        ) : null}
      </React.Fragment>
    );
  }
}

ApexPieChart.propTypes = {
  data: PropTypes.any,
};

export default ApexPieChart;
