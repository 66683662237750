import React, { Component } from "react";
import PropTypes from "prop-types";
import MetaTags from "react-meta-tags";
import Alert from "react-bootstrap/Alert";
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  CardTitle,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table,
  Input,
} from "reactstrap";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

//import Charts
import ExpenseRevenueGraph from "./ExpenseRevenueGraph";
import NumberFormat from "react-number-format";
import { isEmpty, size } from "lodash";
import noGraph from "../../assets/images/custom/no-graph.svg";
//import action
// import { getChartsData } from "../../store/actions";

// Pages Components
import WelcomeComp from "./WelcomeComp";
import MonthlyEarning from "./MonthlyExpenses";
import SocialSource from "./SocialSource";
import ActivityComp from "./ActivityComp";
import TopCities from "./TopCities";
import LatestTranaction from "./LatestTranaction";

//i18n
import { withTranslation } from "react-i18next";
import classNames from "classnames";

// Data
import { getExpenses, getRevenue, resetDateRange } from "store/actions";
import moment from "moment";
import {
  isListUpdated,
  isDateRangeUpdated,
  filterDataFromDateRange,
} from "services/helpers/funcions";

import OnBoarder from "components/Common/OnBoardTutorial";

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dateRange: false,
      reports: [
        { title: "Orders", iconClass: "bx-copy-alt", description: "1,235" },
        {
          title: "Revenue",
          iconClass: "bx-archive-in",
          description: "$35, 723",
        },
        {
          title: "Average Price",
          iconClass: "bx-purchase-tag-alt",
          description: "$16.2",
        },
      ],
      email: [
        { title: "Week", linkto: "#", isActive: false },
        { title: "Month", linkto: "#", isActive: false },
        { title: "Year", linkto: "#", isActive: true },
      ],
      chartSeries: [],
      periodType: "yearly",
      totalExpenses: 0,
      totalRevenue: 0,
      expenses: [],
      revenueList: [],
    };
  }

  componentDidMount() {
    const { expenses, onGetExpenses, revenueList, onGetRevenue, dateRange } =
      this.props;
    var tempExpenses = expenses;
    var tempRevenue = revenueList;

    if (expenses && !expenses.length) {
      onGetExpenses();
    }

    if (revenueList && !revenueList.length) {
      onGetRevenue();
    }

    if (dateRange) {
      this.setState({ dateRange });
      tempExpenses = filterDataFromDateRange(dateRange, expenses);
      tempRevenue = filterDataFromDateRange(dateRange, revenueList);
    }

    let totalExpenses = 0;
    tempExpenses.forEach(item => (totalExpenses += item.amount));

    let totalRevenue = 0;
    tempRevenue.forEach(item => (totalRevenue += item.amount));

    this.setState({
      expenses: tempExpenses,
      revenueList: tempRevenue,
      totalExpenses: totalExpenses,
      totalRevenue: totalRevenue,
    });

    // console.log(this.props.references);
  }

  componentDidUpdate(prevProps) {
    var { expenses, revenueList, dateRange } = this.props;
    var tempExpenses = expenses;
    var tempRevenue = revenueList;

    if (isDateRangeUpdated(dateRange, prevProps.dateRange)) {
      this.setState({ dateRange });

      if (dateRange) {
        tempExpenses = filterDataFromDateRange(dateRange, expenses);
        tempRevenue = filterDataFromDateRange(dateRange, revenueList);
      }
    }

    if (
      !isEmpty(tempExpenses) &&
      size(prevProps.expenses) !== size(tempExpenses)
    ) {
      let totalExpenses = 0;
      tempExpenses.forEach(item => (totalExpenses += item.amount));
      this.setState({ expenses: tempExpenses, totalExpenses: totalExpenses });
    }

    if (
      !isEmpty(tempRevenue) &&
      size(prevProps.revenueList) !== size(tempRevenue)
    ) {
      let totalRevenue = 0;
      tempRevenue.forEach(item => (totalRevenue += item.amount));
      this.setState({ revenueList: tempRevenue, totalRevenue: totalRevenue });
    }
  }

  render() {
    return (
      <React.Fragment>
        <OnBoarder />
        <div className="page-content">
          <MetaTags>
            <title>Mezur.ai | Home</title>
          </MetaTags>
          <Container fluid>
            {/* {this.props.dateRange && (
              <Alert variant="primary">
                Displaying data from{" "}
                {this.props.dateRange.startDate.toString().slice(4, 10)} through{" "}
                {this.props.dateRange.endDate.toString().slice(4, 10)}.{" "}
                <span
                  style={{ textDecoration: "underline", cursor: "pointer" }}
                  onClick={() => {
                    this.props.resetDateRange();
                  }}
                >
                  Reset?
                </span>
              </Alert>
            )} */}
            <Row>
              <Col xl="4">
                <WelcomeComp amount={this.state.totalRevenue} />
                <MonthlyEarning
                  amount={this.state.totalExpenses}
                  expenses={this.state.expenses}
                />
              </Col>
              <Col xl="8">
                <div className="summary">
                  <Row>
                    <Col md="4">
                      <Card className="mini-stats-wid revenue">
                        <CardBody>
                          <div className="d-flex">
                            <div className="flex-grow-1">
                              <p className="text-muted fw-medium">Revenue</p>
                              <NumberFormat
                                thousandsGroupStyle="thousand"
                                className="h4"
                                prefix="$ "
                                placeholder="Enter the amount in USD"
                                decimalSeparator="."
                                displayType="text"
                                type="text"
                                thousandSeparator={true}
                                allowNegative={false}
                                value={this.state.totalRevenue}
                              />
                            </div>
                            <div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
                              <span className="avatar-title">
                                <i className="bx bx-dollar-circle font-size-24" />
                              </span>
                            </div>
                          </div>
                        </CardBody>
                      </Card>
                    </Col>
                    <Col md="4">
                      <Card className="mini-stats-wid expenses">
                        <CardBody>
                          <div className="d-flex">
                            <div className="flex-grow-1">
                              <p className="text-muted fw-medium">Expenses</p>
                              <NumberFormat
                                thousandsGroupStyle="thousand"
                                className="h4"
                                prefix="$ "
                                placeholder="Enter the amount in USD"
                                decimalSeparator="."
                                displayType="text"
                                type="text"
                                thousandSeparator={true}
                                allowNegative={false}
                                value={this.state.totalExpenses}
                              />
                            </div>
                            <div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
                              <span className="avatar-title">
                                <i className="bx bx-credit-card font-size-24" />
                              </span>
                            </div>
                          </div>
                        </CardBody>
                      </Card>
                    </Col>
                    <Col md="4">
                      <Card className="mini-stats-wid profit">
                        <CardBody>
                          <div className="d-flex">
                            <div className="flex-grow-1">
                              <p className="text-muted fw-medium">Profit</p>
                              <span className="h4">$ </span>
                              <NumberFormat
                                thousandsGroupStyle="thousand"
                                className="h4"
                                placeholder="Enter the amount in USD"
                                decimalSeparator="."
                                displayType="text"
                                type="text"
                                thousandSeparator={true}
                                value={
                                  this.state.totalRevenue -
                                  this.state.totalExpenses
                                }
                              />
                            </div>
                            <div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
                              <span className="avatar-title">
                                <i className="bx bx-wallet-alt font-size-24" />
                              </span>
                            </div>
                          </div>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </div>

                <Card>
                  <CardBody>
                    <div className="d-sm-flex flex-wrap">
                      <CardTitle className="mb-0" style={{ fontSize: "18px" }}>
                        Monthly Data
                      </CardTitle>
                    </div>
                    {this.state.expenses.length > 0 ||
                    this.state.revenueList.length > 0 ? (
                      <ExpenseRevenueGraph
                        expenseSeries={this.state.expenses}
                        revenueSeries={this.state.revenueList}
                        dateRange={this.props.dateRange}
                      />
                    ) : (
                      <div className="d-flex flex-column align-items-center">
                        <img
                          src={noGraph}
                          style={{ height: "230px", marginTop: "30px" }}
                          alt=""
                        />
                        <p
                          className="mt-4 mb-0 pt-2"
                          style={{
                            fontSize: "17px",
                            fontWeight: "600",
                          }}
                        >
                          No data
                        </p>
                      </div>
                    )}
                  </CardBody>
                </Card>
              </Col>
            </Row>

            {/* <Row>
              <Col xl="4">
                <SocialSource />
              </Col>
              <Col xl="4">
                <ActivityComp />
              </Col>
              <Col xl="4">
                <TopCities />
              </Col>
            </Row> */}
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

Dashboard.propTypes = {
  t: PropTypes.any,
  // chartsData: PropTypes.any,
  revenueList: PropTypes.array,
  onGetRevenue: PropTypes.func,
  expenses: PropTypes.array,
  onGetExpenses: PropTypes.func,
  failModal: PropTypes.bool,
  onCloseModals: PropTypes.func,
  apiStatusMessage: PropTypes.object,
  dateRange: PropTypes.any,
  resetDateRange: PropTypes.func,
  references: PropTypes.array,
};

const mapStateToProps = state => ({
  revenueList: state.revenue.revenueList,
  expenses: state.expenses.expenses,
  apiStatusMessage: state.expenses.apiStatusMessage,
  dateRange: state.Common.dateRange,
  Account: state.Account,
});

const mapDispatchToProps = dispatch => ({
  onGetExpenses: () => dispatch(getExpenses()),
  onGetRevenue: () => dispatch(getRevenue()),
  resetDateRange: () => dispatch(resetDateRange()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(Dashboard));
