import React, { Component } from "react";
import { Container } from "reactstrap";
import MetaTags from "react-meta-tags";
import ExpensesTable from "./ExpensesTable";

class Expenses extends Component {
  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Mezur.ai | Expenses</title>
          </MetaTags>
          <Container fluid>
            <ExpensesTable />
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

export default Expenses;
