import { call, put, takeEvery } from "redux-saga/effects";

// Ecommerce Redux States
import {
  GET_EXPENSES,
  ADD_NEW_EXPENSE,
  DELETE_EXPENSE,
  UPDATE_EXPENSE,
  IMPORT_EXPENSES,
} from "./actionTypes";

import {
  getExpensesSuccess,
  successModal,
  failModal,
  apiStatus,
} from "./actions";

import {
  getExpenses,
  addNewExpense,
  updateExpense,
  deleteExpense,
  importNewExpenses,
} from "services/expenses";

function* onGetExpenses() {
  try {
    const response = yield call(getExpenses);
    yield put(getExpensesSuccess(response));
  } catch (error) {
    console.log(error);
    yield put(
      apiStatus({
        heading: "Error",
        message: "Could not fetch expenses, please reload the page",
      })
    );
    yield put(failModal());
  }
}

function* onAddNewExpense({ payload: expenseData }) {
  try {
    yield call(addNewExpense, expenseData);
    yield put(
      apiStatus({
        heading: "Expense added",
        message: "Expense added successfully",
      })
    );
    yield put(successModal());
  } catch (error) {
    yield put(
      apiStatus({
        heading: "Error",
        message: "Could not add expense, please try again",
      })
    );
    yield put(failModal());
  }
}

function* onUpdateExpense({ payload: expenseData }) {
  try {
    yield call(updateExpense, expenseData);
    yield put(
      apiStatus({
        heading: "Expense Updated!",
        message: "Expense Updated successfully",
      })
    );
    yield put(successModal());
  } catch (error) {
    yield put(
      apiStatus({
        heading: "Error",
        message: "Could not update expense, please try again",
      })
    );
    yield put(failModal());
  }
}

function* onDeleteExpense({ payload: expenseData }) {
  try {
    yield call(deleteExpense, expenseData);
    yield put(
      apiStatus({
        heading: "Expense Deleted!",
        message: "Expense deleted successfully",
      })
    );
    yield put(successModal());
  } catch (error) {
    yield put(
      apiStatus({
        heading: "Error",
        message: "Could not delete expense, please try again",
      })
    );
    yield put(failModal());
  }
}

function* onImportExpenses({ payload: data }) {
  console.log("Import saga", data);
  try {
    for (let i = 0; i < data.length; i++) {
      yield call(importNewExpenses, data[i].id);
    }
    yield put(
      apiStatus({
        heading: "Expenses Imported!",
        message: "Expenses imported successfully",
      })
    );
    yield put(successModal());
  } catch (error) {
    console.log("EXPENSES IMPORT ERROR", error);
    yield put(
      apiStatus({
        heading: "Error",
        message: "Could not import expenses, please try again",
      })
    );
    yield put(failModal());
  }
}

function* expensesSaga() {
  yield takeEvery(GET_EXPENSES, onGetExpenses);
  yield takeEvery(ADD_NEW_EXPENSE, onAddNewExpense);
  yield takeEvery(UPDATE_EXPENSE, onUpdateExpense);
  yield takeEvery(DELETE_EXPENSE, onDeleteExpense);
  yield takeEvery(IMPORT_EXPENSES, onImportExpenses);
}

export default expensesSaga;
