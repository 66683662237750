import { RESET_DATE_RANGE, SET_DATE_RANGE } from "./actionTypes";

const INIT_STATE = {
  dateRange: false,
};

const Common = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_DATE_RANGE:
      return {
        ...state,
        dateRange: action.payload,
      };

    case RESET_DATE_RANGE:
      console.log("Date reset test", action.payload);
      return {
        ...state,
        dateRange: action.payload,
      };

    default:
      return state;
  }
};

export default Common;
