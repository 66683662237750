import React, { Component } from "react";
import PropTypes from "prop-types";
import ReactDrawer from "react-drawer";
import "react-drawer/lib/react-drawer.css";

import { connect } from "react-redux";

import { Link } from "react-router-dom";
import moment from "moment";

// // reactstrap
// import { Row, Col, Dropdown, DropdownToggle, DropdownMenu } from "reactstrap";
import DropdownButton from "react-bootstrap/DropdownButton";
import Dropdown from "react-bootstrap/Dropdown";

// Import menuDropdown
import LanguageDropdown from "./LanguageDropdown";
import NotificationDropdown from "./NotificationDropdown";
import ProfileMenu from "./ProfileMenu";
import DatePicker from "./DatePicker";

import megamenuImg from "../../assets/images/megamenu-img.png";
import logoSmall from "assets/images/brand-assets/mezur-logo-small.png";
import logoLight from "assets/images/brand-assets/mezur-logo-large-dark.png";
import logoDark from "assets/images/brand-assets/mezur-logo-large-light.png";

import { resetDateRange } from "store/actions";

//i18n
import { withTranslation } from "react-i18next";

import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isSearch: false,
      open: false,
      position: "right",
    };
    this.toggleMenu = this.toggleMenu.bind(this);
    this.toggleFullscreen = this.toggleFullscreen.bind(this);
  }

  toggleSearch = () => {
    this.setState({ isSearch: !this.state.isSearch });
  };
  toggleMenu() {
    // this.props.openLeftMenuCallBack();
  }

  toggleFullscreen() {
    if (
      !document.fullscreenElement &&
      /* alternative standard method */ !document.mozFullScreenElement &&
      !document.webkitFullscreenElement
    ) {
      // current working methods
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen(
          Element.ALLOW_KEYBOARD_INPUT
        );
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();
      }
    }
  }

  resetDateTrigger() {
    const { resetDateRange } = this.props;
    let startDate = moment().subtract(1, "months").set({ date: 1 });

    let defaultDateRange = {
      startDate: startDate.toDate(),
      endDate: moment().toDate(),
      key: "selection",
    };

    console.log("Reset date range", defaultDateRange);
    resetDateRange(defaultDateRange);

    // .format("DD-MM-YYYY");
    // console.log("Start date: ", startDate);

    // let count = 1;
    // while (startDate.format("DD-MM-YYYY") !== moment().format("DD-MM-YYYY")) {
    //   console.log("Days: ", count++);
    //   // console.log(startDate.format("DD-MM-YYYY"));
    //   startDate = startDate.add(1, "days");
    // }
  }

  render() {
    return (
      <React.Fragment>
        <header id="page-topbar">
          <div className="navbar-header">
            <div className="d-flex">
              <div className="navbar-brand-box">
                <Link to="/" className="logo logo-dark">
                  <span className="logo-sm">
                    <img src={logoSmall} alt="" height="22" />
                  </span>
                  <span className="logo-lg">
                    <img src={logoDark} alt="" height="17" />
                  </span>
                </Link>

                <Link to="/" className="logo logo-light">
                  <span className="logo-sm">
                    <img src={logoSmall} alt="" height="22" />
                  </span>
                  <span className="logo-lg">
                    <img src={logoLight} alt="" height="19" />
                  </span>
                </Link>
              </div>
              <button
                type="button"
                className="btn btn-sm px-3 font-size-16 d-lg-none header-item"
                data-toggle="collapse"
                onClick={this.toggleMenu}
                data-target="#topnav-menu-content"
              >
                <Dropdown>
                  <Dropdown.Toggle
                    id="dropdown-basic"
                    style={{ backgroundColor: "transparent", border: "none" }}
                  >
                    <i className="fa fa-fw fa-bars" />
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item>
                      <Link
                        className="nav-link dropdown-toggle arrow-none d-flex align-items-center"
                        to="/home"
                      >
                        <i className="bx bx-home-circle me-1" />
                        <p className="m-0">Home</p>
                      </Link>
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <Link
                        to="/expenses"
                        className="nav-link dropdown-toggle arrow-none d-flex"
                      >
                        <i className="bx bx-dollar-circle pt-1 me-1" />
                        <p className="m-0">Expenses</p>
                      </Link>
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <Link
                        to="/revenue"
                        className="nav-link dropdown-toggle arrow-none d-flex"
                      >
                        <i className="bx bx-wallet-alt pt-1 me-1" />
                        <p className="m-0">Revenue</p>
                      </Link>
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <Link
                        to="/bank-accounts"
                        className="nav-link dropdown-toggle arrow-none d-flex"
                      >
                        <i className="bx bxs-bank pt-1 me-1" />
                        <p className="m-0">Bank Accounts</p>
                      </Link>
                    </Dropdown.Item>

                  </Dropdown.Menu>
                </Dropdown>
              </button>
            </div>

            <div className="d-flex">
              <DatePicker />
              {/* <LanguageDropdown /> */}
              {/* <NotificationDropdown /> */}
              <OverlayTrigger
                // trigger="hover"
                key="bottom"
                placement="bottom"
                overlay={
                  <Popover id={`popover-positioned-bottom`}>
                    <Popover.Header as="h3">Reset date range</Popover.Header>
                    <Popover.Body>
                      Clicking on this will <strong>reset</strong> the date
                      range back to default.
                    </Popover.Body>
                  </Popover>
                }
              >
                <div
                  style={{
                    marginTop: "auto",
                    marginBottom: "auto",
                    cursor: "pointer",
                  }}
                  className="date-reset"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    fill="white"
                    className="bi bi-arrow-clockwise"
                    viewBox="0 0 16 16"
                    onClick={() => {
                      this.resetDateTrigger();
                    }}
                  >
                    <path
                      fillRule="evenodd"
                      d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2v1z"
                    />
                    <path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466z" />
                  </svg>
                </div>
              </OverlayTrigger>

              <ProfileMenu />

              {/* <div className="dropdown d-none d-lg-inline-block ms-1">
                <button
                  type="button"
                  className="btn header-item noti-icon"
                  onClick={this.toggleFullscreen}
                  data-toggle="fullscreen"
                >
                  <i className="bx bx-fullscreen" />
                </button>
              </div> */}
              {/* <div className="dropdown d-inline-block">
                <button
                  onClick={this.toggleRightDrawer}
                  disabled={this.state.open}
                  type="button"
                  className="btn header-item noti-icon right-bar-toggle"
                >
                  <i className="bx bx-cog bx-spin" />
                </button>
              </div> */}
            </div>
          </div>
        </header>
        {/* <ReactDrawer
          open={this.state.open}
          position={this.state.position}
          onClose={this.onDrawerClose}
        >
          <RightSidebar onClose={this.onDrawerClose} />
        </ReactDrawer> */}
      </React.Fragment>
    );
  }
}

// Header.propTypes = {
//   openLeftMenuCallBack: PropTypes.func,
//   t: PropTypes.any,
// };

// const mapStatetoProps = state => {};

// export default connect(mapStatetoProps, {})(withTranslation()(Header));

Header.propTypes = {
  resetDateRange: PropTypes.func,
  references: PropTypes.array,
};

const mapDispatchToProps = dispatch => ({
  resetDateRange: data => dispatch(resetDateRange(data)),
});

export default connect(null, mapDispatchToProps)(Header);
