import { get, del } from "./helpers/api_helper";
import * as url from "./helpers/url_helper";

const getBankAccounts = () => get(url.GET_BANKS);
const getBankAccountTransactions = accountID =>
  get(url.GET_TRANSACTIONS + accountID);

const deleteBank = data =>
  del(url.DELETE_BANK, data).then(res => {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        if (res) {
          resolve(res);
        } else {
          reject("Error: Cannot delete bank account");
        }
      });
    });
  });

export { getBankAccounts, getBankAccountTransactions, deleteBank };
