import { get, post, put, del } from "./helpers/api_helper";
import * as url from "./helpers/url_helper";

//  REVENUE
const getRevenue = () => get(url.GET_REVENUE);

// Add Revenue
const addNewRevenue = revenueData =>
  post(url.ADD_REVENUE, revenueData).then(res => {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        if (res) {
          resolve(res);
        } else {
          reject("Error: Cannot add revenue");
        }
      });
    });
  });

// Delete Revenue
const deleteRevenue = revenueData =>
  del(url.DELETE_REVENUE, revenueData).then(res => {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        if (res) {
          resolve(res);
        } else {
          reject("Error: Cannot delete revenue");
        }
      });
    });
  });

// Update Revenue
const updateRevenue = revenueData =>
  put(url.UPDATE_REVENUE + "/" + `${revenueData.id}`, revenueData.data).then(
    res => {
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          if (res) {
            resolve(res);
          } else {
            reject("Error: Cannot update revenue");
          }
        });
      });
    }
  );

const importNewRevenue = bankID =>
  post(url.IMPORT_REVENUE + bankID).then(res => {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        if (res) {
          resolve(res);
        } else {
          reject("Error: Cannot import revenue");
        }
      });
    });
  });

export {
  getRevenue,
  addNewRevenue,
  deleteRevenue,
  updateRevenue,
  importNewRevenue,
};
