import { combineReducers } from "redux";

// COMMON
import Common from "./common/reducer";

// AUTHENTICATION
import Login from "./auth/login/reducer";
import Account from "./auth/register/reducer";

// EXPENSES
import expenses from "./expenses/reducer";

// REVENUE
import revenue from "./revenue/reducer";

// BANKS
import banks from "./banks/reducer";

const rootReducer = combineReducers({
  Common,
  Login,
  Account,
  expenses,
  revenue,
  banks,
});

export default rootReducer;
