import {
  SUCCESS_MODAL,
  FAIL_MODAL,
  CLOSE_MODALS,
  API_ERROR,
  GET_BANKS_SUCCESS,
  GET_BANK_TRANSACTIONS_SUCCESS,
  // ADD_NEW_BANK,
  DELETE_BANKS,
} from "./actionTypes";

const INIT_STATE = {
  bankAccounts: [],
  bankTransactions: [],
  successModal: false,
  failModal: false,
  apiStatusMessage: {
    heading: "Initial Heading",
    message: "Initial Message",
  },
};

const Banks = (state = INIT_STATE, action) => {
  switch (action.type) {
    // MODAL CASES
    case SUCCESS_MODAL:
      return {
        ...state,
        successModal: true,
      };

    case FAIL_MODAL:
      return {
        ...state,
        failModal: true,
      };

    case CLOSE_MODALS:
      return {
        ...state,
        successModal: false,
        failModal: false,
      };

    case API_ERROR:
      return {
        ...state,
        apiStatusMessage: action.payload,
      };

    // API CALL CASES
    case GET_BANKS_SUCCESS:
      return {
        ...state,
        bankAccounts: action.payload,
      };

    case GET_BANK_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        bankTransactions: action.payload,
      };

    default:
      return state;
  }
};

export default Banks;
