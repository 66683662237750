import { call, put, takeEvery } from "redux-saga/effects";
import { GET_BANKS, GET_BANK_TRANSACTIONS, DELETE_BANK } from "./actionTypes";

import {
  getBanksSuccess,
  getBankTransactionsSuccess,
  successModal,
  failModal,
  apiStatus,
} from "./actions";

import {
  getBankAccounts,
  getBankAccountTransactions,
  deleteBank,
} from "services/banks";

function* onGetBanks() {
  // console.log("Get banks function executed");
  try {
    const response = yield call(getBankAccounts);
    yield put(getBanksSuccess(response));
  } catch (error) {
    console.log(error);
    if (error.response.status !== 403) {
      yield put(
        apiStatus({
          heading: "Error",
          message: "Could not fetch bank accounts, please reload the page",
        })
      );
      yield put(failModal());
    }
  }
}

function* onGetBankTransactions({ payload: data }) {
  // console.log("Get transactions function executed", data);
  try {
    const response = yield call(getBankAccountTransactions, data);
    yield put(getBankTransactionsSuccess(response));
  } catch (error) {
    console.log(error);
    yield put(
      apiStatus({
        heading: "Error",
        message:
          "Could not fetch bank account transactions, please reload the page",
      })
    );
    yield put(failModal());
  }
}

function* onDeleteBank({ payload: data }) {
  // console.log("log delete function data", data);
  try {
    yield call(deleteBank, data);
    yield put(
      apiStatus({
        heading: "Bank account deleted!",
        message: "Your bank account has been deleted successfully",
      })
    );
    yield put(successModal());
  } catch (error) {
    yield put(
      apiStatus({
        heading: "Error",
        message: "Could not delete bank account, please try again",
      })
    );
    yield put(failModal());
  }
}

function* banksSaga() {
  yield takeEvery(GET_BANKS, onGetBanks);
  yield takeEvery(GET_BANK_TRANSACTIONS, onGetBankTransactions);
  yield takeEvery(DELETE_BANK, onDeleteBank);
}

export default banksSaga;
