import {
  GET_EXPENSES,
  SUCCESS_MODAL,
  FAIL_MODAL,
  CLOSE_MODALS,
  API_ERROR,
  GET_EXPENSES_SUCCESS,
  ADD_NEW_EXPENSE,
  UPDATE_EXPENSE,
  DELETE_EXPENSE,
  IMPORT_EXPENSES,
} from "./actionTypes";

// MODAL METHODS
export const successModal = () => ({
  type: SUCCESS_MODAL,
});

export const failModal = () => ({
  type: FAIL_MODAL,
});

export const closeModals = () => ({
  type: CLOSE_MODALS,
});

export const apiStatus = message => ({
  type: API_ERROR,
  payload: message,
});

// API METHODS
export const getExpenses = () => ({
  type: GET_EXPENSES,
});

export const getExpensesSuccess = expenses => ({
  type: GET_EXPENSES_SUCCESS,
  payload: expenses,
});

export const addNewExpense = expense => ({
  type: ADD_NEW_EXPENSE,
  payload: expense,
});

export const updateExpense = expense => ({
  type: UPDATE_EXPENSE,
  payload: expense,
});

export const deleteExpense = expense => ({
  type: DELETE_EXPENSE,
  payload: expense,
});

export const importExpenses = data => ({
  type: IMPORT_EXPENSES,
  payload: data,
});
