import React, { Component } from "react";
import { BrowserRouter as Router, Switch } from "react-router-dom";

// Import Routes
import { authProtectedRoutes, publicRoutes } from "./routes/";
import AppMiddleware from "./routes/route";

import NonAuthLayout from "./components/Authentication/NonAuthLayout";
import Layout from "./components/Dashboard";
import AccountLayout from './components/Account/Layout'

// React feedback form
import Feedback from "feeder-react-feedback";
import "feeder-react-feedback/dist/feeder-react-feedback.css";

// Import scss
import "./assets/scss/theme.scss";

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.testRef = React.createRef(App);
  }

  render() {
    function changeBodyAttribute(attribute, value) {
      if (document.body) document.body.setAttribute(attribute, value);
      return true;
    }

    changeBodyAttribute("data-topbar", "dark");
    changeBodyAttribute("data-layout", "horizontal");
    changeBodyAttribute("data-layout-size", "fluid");
    changeBodyAttribute("data-layout-scrollable", "false");

    return (
      <div>
        <React.Fragment>
          <Router basename={process.env.PUBLIC_URL}>
            <Switch>
              {publicRoutes.map((route, idx) => (
                <AppMiddleware
                  path={route.path}
                  layout={NonAuthLayout}
                  component={route.component}
                  key={idx}
                  isAuthProtected={false}
                />
              ))}

              {authProtectedRoutes.map((route, idx) =>
                route.path === "/account-settings" ? (
                  <AppMiddleware
                    path={route.path}
                    component={route.component}
                    layout={AccountLayout}
                    key={idx}
                    isAuthProtected={true}
                    exact
                  />
                ) : (
                  <AppMiddleware
                    path={route.path}
                    layout={Layout}
                    component={route.component}
                    key={idx}
                    isAuthProtected={true}
                    exact
                  />
                )
              )}
            </Switch>
          </Router>
          <Feedback
            projectId="618fa92de0e63e0004ffa12c"
            email={true}
            primaryColor="#706AFB"
            hoverBorderColor="#706AFB"
          />
        </React.Fragment>
      </div>
    );
  }
}

export default App;
