import React, { Component } from "react";
import { AvField } from "availity-reactstrap-validation";
import { Input } from "reactstrap";
import PropTypes from "prop-types";

class PasswordInput extends Component {
  static propTypes = {
    updateInput: PropTypes.any,
  };

  state = {
    passwordState: false,
  };

  handleViewPassword(e) {
    e.preventDefault();
    this.setState(prevState => ({ passwordState: !prevState.passwordState }));
  }

  handlePassword(e) {
    this.props.updateInput(e.target.value, e.target.name);
  }

  render() {
    return (
      <div className="password-input-wrapper">
        <Input
          name="password"
          className="form-control"
          placeholder="Enter Password"
          onChange={this.handlePassword.bind(this)}
          type={this.state.passwordState ? "text" : "password"}
          required
        />
        <button
          className="password-view-toggle form-control"
          onClick={this.handleViewPassword.bind(this)}
        >
          <i
            className={
              this.state.passwordState ? "fa fa-eye" : "fa fa-eye-slash"
            }
            aria-hidden="true"
          ></i>
        </button>
      </div>
    );
  }
}

export default PasswordInput;
