import React, { Component } from "react";
import { Container } from "reactstrap";
import MetaTags from "react-meta-tags";

import BankAccounts from "./BankAccounts";

class Banks extends Component {
  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Mezur | Bank Accounts</title>
          </MetaTags>
          <Container fluid>
            <BankAccounts />
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

export default Banks;
