export const categoryOptions = [
  "Electronics",
  "Food",
  "Stationary",
  "Education",
  "Medical",
  "Loan",
  "Insurance",
  "Travel",
  "Legal",
  "Miscellaneous",
];

export const revenueCategoryOptions = [
  "Sales",
  "Income"
];

export const reactSelectOptions = {
  control: (provided, state) => ({
    ...provided,
    borderColor: "#ced4da !important",
    boxShadow: "none",
  }),
};

export const dateFormat = "DD-MM-YYYY";
