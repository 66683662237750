import {
  SUCCESS_MODAL,
  FAIL_MODAL,
  CLOSE_MODALS,
  API_ERROR,
  GET_BANKS,
  GET_BANKS_SUCCESS,
  GET_BANK_TRANSACTIONS,
  GET_BANK_TRANSACTIONS_SUCCESS,
  DELETE_BANK,
} from "./actionTypes";

// MODAL METHODS
export const successModal = () => ({
  type: SUCCESS_MODAL,
});

export const failModal = () => ({
  type: FAIL_MODAL,
});

export const closeModals = () => ({
  type: CLOSE_MODALS,
});

export const apiStatus = message => ({
  type: API_ERROR,
  payload: message,
});

// API METHODS
export const getBanks = () => ({
  type: GET_BANKS,
});

export const getBankTransactions = data => ({
  type: GET_BANK_TRANSACTIONS,
  payload: data,
});

export const getBanksSuccess = data => ({
  type: GET_BANKS_SUCCESS,
  payload: data,
});

export const getBankTransactionsSuccess = data => ({
  type: GET_BANK_TRANSACTIONS_SUCCESS,
  payload: data,
});

export const deleteBank = data => ({
  type: DELETE_BANK,
  payload: data,
});
