import React, { Component } from "react";
import PropTypes from "prop-types";
import { Alert, Card, CardBody, Col, Container, Row } from "reactstrap";

// availity-reactstrap-validation
import { AvField, AvForm } from "availity-reactstrap-validation";

// action
import { registerUser, registerUserFailed } from "../../store/actions";

// Redux
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";

import logo from "../../assets/images/brand-assets/mezur-logo-large-light.png";
import MetaTags from "react-meta-tags";
import store from "store/index";
import Loader from "components/Common/Loader";
// import { useSelector } from "react-redux";

class Register extends Component {
  constructor(props) {
    super(props);

    // handleValidSubmit
    this.handleValidSubmit = this.handleValidSubmit.bind(this);
  }

  // handleValidSubmit
  handleValidSubmit(event, values) {
    this.props.registerUser(values, this.props.history);
  }

  // componentDidMount() {
  //   this.props.apiError("");
  //   this.props.registerUserFailed("");
  // }

  render() {
    return (
      <React.Fragment>
        <MetaTags>
          <title>Mezur.ai | Register</title>
        </MetaTags>
        {this.props.loading ? <Loader /> : null}
        <div className="account-pages my-5 pt-sm-5">
          <Container>
            <Row className="justify-content-center">
              <Col md={8} lg={8} xl={5}>
                <Card className="overflow-hidden">
                  <div className="d-flex justify-content-center">
                    <img
                      src={logo}
                      width="60%"
                      className="p-5"
                      alt="Mezur logo"
                    />
                  </div>
                  <CardBody className="pt-0">
                    <div className="p-2">
                      <AvForm
                        className="form-horizontal"
                        onValidSubmit={this.handleValidSubmit}
                      >
                        {this.props.registrationError && !this.props.user ? (
                          <Alert color="danger">
                            The user already exists, please click on forgot
                            password to reset your password
                          </Alert>
                        ) : null}

                        {!this.props.registrationError && this.props.user ? (
                          <Alert color="success">
                            User registered successfully
                          </Alert>
                        ) : null}

                        <div className="mb-3">
                          <AvField
                            name="email"
                            label="Email"
                            className="form-control"
                            type="email"
                            required
                          />
                        </div>

                        <div className="mb-3">
                          <AvField
                            name="first_name"
                            label="First Name"
                            type="text"
                            required
                          />
                        </div>

                        <div className="mb-3">
                          <AvField
                            name="last_name"
                            label="Last Name"
                            type="text"
                            required
                          />
                        </div>

                        <div className="mb-3">
                          <AvField
                            name="password"
                            label="Password"
                            type="password"
                            required
                          />
                        </div>

                        <div className="mt-4 d-grid">
                          <button
                            className="btn btn-primary btn-block"
                            type="submit"
                          >
                            Register
                          </button>
                        </div>

                        {/* <div className="mt-4 text-center">
                          <h5 className="font-size-14 mb-3">Sign up using</h5>

                          <ul className="list-inline">
                            <li className="list-inline-item">
                              <Link
                                to="#"
                                className="social-list-item bg-primary text-white border-primary"
                              >
                                <i className="mdi mdi-facebook" />
                              </Link>
                            </li>{" "}
                            <li className="list-inline-item">
                              <Link
                                to="#"
                                className="social-list-item bg-info text-white border-info"
                              >
                                <i className="mdi mdi-twitter" />
                              </Link>
                            </li>{" "}
                            <li className="list-inline-item">
                              <Link
                                to="#"
                                className="social-list-item bg-danger text-white border-danger"
                              >
                                <i className="mdi mdi-google" />
                              </Link>
                            </li>
                          </ul>
                        </div> */}

                        <div className="mt-4 text-center">
                          <p className="m-0">
                            Already have an account ?{" "}
                            <Link
                              to="/login"
                              className="fw-medium text-primary"
                            >
                              Login
                            </Link>
                          </p>
                        </div>

                        {/* <div className="mt-2 text-center">
                          <p className="mb-0">
                            By registering you agree to the Mezur.ai{" "}
                            <Link to="#" className="text-primary">
                              Terms of Use
                            </Link>
                          </p>
                        </div> */}
                      </AvForm>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

Register.propTypes = {
  registerUser: PropTypes.func,
  registerUserFailed: PropTypes.any,
  registrationError: PropTypes.any,
  user: PropTypes.object,
  history: PropTypes.object,
  loading: PropTypes.bool,
};

const mapStateToProps = state => {
  const { user, registrationError, loading } = state.Account;
  return { user, registrationError, loading };
};

export default withRouter(
  connect(mapStateToProps, {
    registerUser,
    registerUserFailed,
  })(Register)
);
